
export function formModificaTicketFieldsInit(quillRef, selectRef, setQuestionBlocks, setBlockNumber){
    var ticketId = sessionStorage.getItem('editTicketId');
    var uid = localStorage.getItem('uid');
    var tickets = JSON.parse(localStorage.getItem('tickets'));
    var ticket;
    var ticketsBozze;

    if (tickets !== null) {
        ticket = tickets.filter(ticket => ticket.Id === ticketId)[0];
        if (ticket === undefined) {
            ticketsBozze = JSON.parse(localStorage.getItem('ticketsBozze'));
            ticket = ticketsBozze.filter(ticket => ticket.Id === ticketId)[0];
        }
    } else {
        ticketsBozze = JSON.parse(localStorage.getItem('ticketsBozze'));
        ticket = ticketsBozze.filter(ticket => ticket.Id === ticketId)[0];
    }

    document.getElementById('nTicket').textContent = ticket.Id;

    var richieste = '';
    if ( ticket != undefined && ticket.Richieste_Tickets__r !== undefined) {
        richieste = ticket.Richieste_Tickets__r.records;
    }

    var validationSede = document.getElementById('validationSede');
    var validationCanale = document.getElementById('validationCanale');
    var validationSesso = document.getElementById('validationSesso');
    var validationLingua = document.getElementById('validationLingua');
    var validationNazione = document.getElementById('validationNazione');
    var validationRegione = document.getElementById('validationRegione');
    var validationComune = document.getElementById('validationComune');
    var validationCategorie = document.getElementById('validationCategoria');
    var validationData = document.getElementById('validationData');
    var validationRipeti = document.getElementById('validationPersone');
    var showTicketNominativo = document.getElementById('showTicketNominativo');//checkbox
    var boxTicketNominativo = document.getElementById('boxTicketNominativo');
    var validationEmail = document.getElementById('validationEmail');
    var validationNome = document.getElementById('validationNome');
    var validationCognome = document.getElementById('validationCognome');
    var validationRS = document.getElementById('validationRS');
    var typeDropdownButton = document.getElementById('typeDropdownButton');
    var validationComuneRS = document.getElementById('validationComuneRS');
    var validationNazioneRS = document.getElementById('validationNazioneRS');
    var validationTitolo = document.getElementById('validationTitolo');
    var validationRuolo = document.getElementById('validationRuolo');
    var validationProfessione = document.getElementById('validationProfessione');
    var validationMdT = document.getElementById('validationMdT');
    var validationCdV = document.getElementById('validationCdV');
    var validationGruppoOrganizzato  = document.getElementById('validationGruppoOrganizzato');
    var validationTdR0 = document.getElementById('validationTdR0'); //select
    var validationRS0 = document.getElementById('validationRS0'); //input
    var floatingTextarea = document.getElementById('floatingTextarea0');
    var closeRequest = document.getElementById('closeRequest0');
    var buttonNewRequest = document.getElementById('newRequest');
    var buttonDeleteRequest = document.getElementById('deleteRequest');
    var validationOperatore = document.getElementById('validationOperatore');
    var validationOperatore2 = document.getElementById('validationOperatore2');
    var showEmail = document.getElementById('showEmail');
    var boxEmail = document.getElementById('boxEmail');
    var validationMittente = document.getElementById('validationMittente');
    var validationCC = document.getElementById('validationCC');
    var validationOggetto = document.getElementById('validationOggetto');
    var editor = document.getElementById('editor');

    if (ticket.Canale__c && ticket.Canale__c !== '') {
        validationCanale.value = ticket.Canale__c.toUpperCase();
    }
    validationSesso.value = ticket.Sesso__c || '';
    validationLingua.value = ticket.Lingua__c || '';
    validationNazione.value = ticket.Provenienza_Nazione__c || '';
    validationRegione.value = ticket.Provenienza_Regione__c || '';
    validationComune.value = ticket.Provenienza_Comune__c || '';
    var oldComune = ticket.Provenienza_Comune__c || '';
    var oldEmail = '';
    var oldNome = '';
    var oldCognome = '';

//if (validationComune.value != ''){
//  validationComune.disabled = true;
//}
    validationData.value = ticket.Data__c || '';
    validationRipeti.value = ticket.Ripeti_per_N__c || '';
    if (ticket.Stato_Ticket__c === "2") {
        showTicketNominativo.checked = true;
        boxTicketNominativo.style.display = 'block';
        validationEmail.value = ticket.Contact.Email || '';
        oldEmail = ticket.Contact.Email || '';
        //validationEmail.disabled = true;
        validationNome.value = ticket.Contact.FirstName || '';
        oldNome = ticket.Contact.FirstName || '';
        //validationNome.disabled = true;
        validationCognome.value = ticket.Contact.LastName || '';
        oldCognome = ticket.Contact.LastName || '';
        //validationCognome.disabled = true;
        validationRS.value = ticket.Ragione_sociale__c || '';
        validationComuneRS.value = ticket.Comune_Azienda__c || '';
        validationNazioneRS.value = ticket.Nazione_Azienda__c || '';
        typeDropdownButton.innerHTML = ticket.Tipologia_Azienda__c || '';
        validationTitolo.value = ticket.Contact.Title || '';
        validationRuolo.value = ticket.Contact.Ruolo__c || '';
        validationProfessione.value = ticket.Contact.Professione__c || '';
        validationMdT.value = ticket.Mezzo_di_Trasporto__c || '';
        validationCdV.value = ticket.Compagni_di_viaggio__c || '';
        let groupSelect = document.getElementById('validationCdV');
        let labelGruppoOrganizzato = document.getElementById('labelSSpecifica');
        if (groupSelect.value === 'Gruppo organizzato (specificare)') {
            validationGruppoOrganizzato.style.display = 'block';
            labelGruppoOrganizzato.style.display = 'block';
            validationGruppoOrganizzato.value = ticket.Gruppo_organizzato_specifica__c;
        } else {
            validationGruppoOrganizzato.style.display = 'none';
            labelGruppoOrganizzato.style.display = 'none';
        }
        //primo elemento di Richieste_Tickets__r
        if (ticket && ticket.Richieste_Tickets__r && ticket.Richieste_Tickets__r.records) {
            switch (ticket.Richieste_Tickets__r.records[0].Tipo_richiesta__c) {
                case 'Risorsa':
                    validationTdR0.value = 'resources';
                    break;
                case 'Evento':
                    validationTdR0.value = 'events';
                    break;
                case 'Prodotto':
                    validationTdR0.value = 'products';
                    break;
                case 'Tabella':
                    validationTdR0.value = 'tables';
                    break;
                case 'Altro':
                    validationTdR0.value = 'altro';
                    break;
                default:
                    validationTdR0.value = '';
            }

            validationRS0.value = ticket.Richieste_Tickets__r.records[0].Richiesta_specifica__c || '';
            floatingTextarea.value = ticket.Richieste_Tickets__r.records[0].Richiesta_Altro__c || '';
            closeRequest.checked = ticket.Richieste_Tickets__r.records[0].Chiusa__c;
            var selectedIds = JSON.parse(sessionStorage.getItem('selectedIds')) || {};
            selectedIds[0] = ticket.Richieste_Tickets__r.records[0].Risorsa_External_Id__c || '';
            sessionStorage.setItem('selectedIds', JSON.stringify(selectedIds));
            sessionStorage.setItem('questionBlocks', "1");
        }

        validationOperatore.value = ticket.Coda_Assegnazione__c;
        validationOperatore2.value = ticket.Notifica_Ticket__c;
        if (ticket.Invio_Email__c) {
            showEmail.checked = true;
            boxEmail.style.display = 'block';
        } else {
            showEmail.checked = false;
            boxEmail.style.display = 'none';
        }

        let templateSelect = document.getElementById('validationTemplate');
        validationMittente.value = ticket.Sender__c;
        validationCC.value = ticket.Email_CC__c || '';
        validationOggetto.value = ticket.Oggetto_Email__c || '';
        const quill = quillRef.current.getEditor();
        quill.clipboard.dangerouslyPasteHTML(ticket.Testo_Email__c);
        let cover = ticket.Cover__c || '';
        if (cover !== '') {
            sessionStorage.setItem('cover', cover);
        }
        templateSelect.value = ticket.Oggetto_Email__c;
    }

    if (ticket && ticket.Richieste_Tickets__r && ticket.Richieste_Tickets__r.records) {
        ticket.Richieste_Tickets__r.records.forEach((record, index) => {
            if (index > 0) {
                switch (record.Tipo_richiesta__c) {
                    case 'Risorsa':
                        document.querySelector('[data-question="' + index + '"] #validationTdR' + index).value = 'resources';
                        break;
                    case 'Evento':
                        document.querySelector('[data-question="' + index + '"] #validationTdR' + index).value = 'events';
                        break;
                    case 'Prodotto':
                        document.querySelector('[data-question="' + index + '"] #validationTdR' + index).value = 'products';
                        break;
                    case 'Tabella':
                        document.querySelector('[data-question="' + index + '"] #validationTdR' + index).value = 'tables';
                        break;
                    case 'Altro':
                        document.querySelector('[data-question="' + index + '"] #validationTdR' + index).value = 'altro';
                        break;
                    default:
                        document.querySelector('[data-question="' + index + '"] #validationTdR' + index).value = '';
                }
                document.querySelector('[data-question="' + index + '"] #validationRS' + index).value = record.Richiesta_specifica__c;
                document.querySelector('[data-question="' + index + '"] #floatingTextarea' + index).value = record.Richiesta_Altro__c || '';
                if (record.Chiusa__c === true) {
                    document.getElementById('closeRequest' + index).checked = true;
                }
                var selectedIds= JSON.parse(sessionStorage.getItem('selectedIds')) || {};
                selectedIds[index] = record.Risorsa_External_Id__c;
                sessionStorage.setItem('selectedIds', JSON.stringify(selectedIds));
                sessionStorage.setItem('questionBlocks', (index + 1).toString());
            }
        });
    }
}