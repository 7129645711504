//npm install --save @types/react-router-dom

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import LoginPage from './pages/Login/LoginPage';
import DashboardPage from './pages/Dashboard/DashboardPage';
import TicketsPage from './pages/Tickets/TicketsPage';
import CreateTicketPage from './pages/CreaTicket/CreateTicketPage';
import ModificaTicketPage from "./pages/ModificaTicket/ModificaTicketPage";
import WebStorageCache from 'web-storage-cache';
import { LoadingContext} from "./utils/LoadingContext";

const webStorageCache = new WebStorageCache();

const LoginRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedIsLoggedIn = webStorageCache.get('isLoggedIn');
    if (storedIsLoggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    webStorageCache.set('isLoggedIn', isLoggedIn);
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [isLoggedIn, navigate]);

  return isLoggedIn ? null : <LoginPage onLogin={() => setIsLoggedIn(true)}/>;
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
      <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginRoute />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/" element={<LoginRoute />} />
            <Route path="/tickets/Tickets" element={<TicketsPage />} />
            <Route path="/tickets/Bozze" element={<TicketsPage />} />
            <Route path="/crea-ticket" element={<CreateTicketPage />} />
            <Route path="/modifica-ticket" element={<ModificaTicketPage />} />
          </Routes>
        </Router>
      </LoadingContext.Provider>
  );
};

export default App;