export const professioni = [
    "Archeologo",
    "Architetto",
    "Assegnista",
    "Avvocato",
    "Cameraman",
    "Chef",
    "Commercialista",
    "Conduttore",
    "Designer",
    "Dietologo",
    "Editor",
    "Egittologo",
    "Geografo",
    "Iconografo",
    "Interior Designer",
    "Militare",
    "Prefetto",
    "Presentatore",
    "Procuratore",
    "Redattore Web",
    "Regista",
    "Scrittore",
    "Showman",
    "Social Media Manager",
    "Sociologo",
    "Vescovo",
    "Web Content Manager",
    "Web Designer",
    "Webmaster",
    "Accompagnatore cicloturistico",
    "Accompagnatore di turismo equestre",
    "Accompagnatore naturalistico o Guida Escursionistica ambientale",
    "Accompagnatori di media montagna",
    "Guida Alpina",
    "Istruttore di canoa e kayak"
];

export const contacts = [
    { firstName: "Sabrina", lastName: "Marretta", personalEmail: "s.marretta@turismotorino.org", activityEmail: "info.desk@turismotorino.org" },
    { firstName: "Erika", lastName: "Carosso", personalEmail: "e.carosso@turismotorino.org", activityEmail: "resguide@turismotorino.org" },
    { firstName: "Elisa", lastName: "Alessi", personalEmail: "e.alessi@turismotorino.org", activityEmail: "reshotel@turismotorino.org" },
    { firstName: "Monica", lastName: "Piras", personalEmail: "m.piras@turismotorino.org", activityEmail: "shop@turismotorino.org" },
    { firstName: "Salvatrice", lastName: "Contarino", personalEmail: "s.contarino@turismotorino.org", activityEmail: "info.desk@turismotorino.org" },
    { firstName: "Chiara", lastName: "Varetto", personalEmail: "c.varetto@turismotorino.org", activityEmail: "resguide@turismotorino.org" },
    { firstName: "Antonella", lastName: "Francolino", personalEmail: "a.francolino@turismotorino.org", activityEmail: "shop@turismotorino.org" },
    { firstName: "Manuela", lastName: "Buzio", personalEmail: "m.buzio@turismotorino.org", activityEmail: "shop@turismotorino.org" },
    { firstName: "Alessia", lastName: "Timon", personalEmail: "a.timon@turismotorino.org", activityEmail: "info.bardonecchia@turismotorino.org" },
    { firstName: "Roberta", lastName: "Deyme", personalEmail: "r.deyme@turismotorino.org", activityEmail: "info.bardonecchia@turismotorino.org" },
    { firstName: "Giovanna", lastName: "Callet", personalEmail: "g.callet@turismotorino.org", activityEmail: "info.bardonecchia@turismotorino.org" },
    { firstName: "Benedetta", lastName: "Zacchero", personalEmail: "b.zacchero@turismotorino.org", activityEmail: "info.cesana@turismotorino.org" },
    { firstName: "Antonella", lastName: "Milia", personalEmail: "a.milia@turismotorino.org", activityEmail: "info.cesana@turismotorino.org" },
    { firstName: "Loredana", lastName: "Rivetti", personalEmail: "l.rivetti@turismotorino.org", activityEmail: "info.claviere@turismotorino.org" },
    { firstName: "Alessandra", lastName: "Brun", personalEmail: "a.brun@turismotorino.org", activityEmail: "info.claviere@turismotorino.org" },
    { firstName: "Iside", lastName: "Negrinotti", personalEmail: "i.negrinotti@turismotorino.org", activityEmail: "info.ivrea@turismotorino.org" },
    { firstName: "Claudia", lastName: "Cossano", personalEmail: "c.cossano@turismotorino.org", activityEmail: "info.ivrea@turismotorino.org" },
    { firstName: "Daniela", lastName: "Benech", personalEmail: "d.benech@turismotorino.org", activityEmail: "info.pinerolo@turismotorino.org" },
    { firstName: "Monica", lastName: "Berton", personalEmail: "m.berton@turismotorino.org", activityEmail: "info.pragelato@turismotorino.org" },
    { firstName: "Agnes", lastName: "Rostan", personalEmail: "a.rostan@turismotorino.org", activityEmail: "info.pragelato@turismotorino.org" },
    { firstName: "Milena", lastName: "Blanc", personalEmail: "m.blanc@turismotorino.org", activityEmail: "info.pragelato@turismotorino.org" },
    { firstName: "Cinzia", lastName: "Eydallin", personalEmail: "c.eydallin@turismotorino.org", activityEmail: "info.sauze@turismotorino.org" },
    { firstName: "Federica", lastName: "Perron", personalEmail: "f.perron@turismotorino.org", activityEmail: "info.sauze@turismotorino.org" },
    { firstName: "Antonella", lastName: "Valsesia", personalEmail: "a.valsesia@turismotorino.org", activityEmail: "info.sestriere@turismotorino.org" },
    { firstName: "Simona", lastName: "Crespi", personalEmail: "s.crespi@turismotorino.org", activityEmail: "info.sestriere@turismotorino.org" },
    { firstName: "Susy", lastName: "Sibille", personalEmail: "s.sibille@turismotorino.org", activityEmail: "info.susa@turismotorino.org" },
    { firstName: "Sofia", lastName: "Pecchenino", personalEmail: "s.pecchenino@turismotorino.org", activityEmail: "info.susa@turismotorino.org" },
    { firstName: "Elisa", lastName: "Alessi", personalEmail: "e.alessi@turismotorino.org", activityEmail: "reshotel@turismotorino.org" },
    { firstName: "Elena Adele", lastName: "Zappia", personalEmail: "e.zappia@turismotorino.org", activityEmail: "reshotelolimpico@turismotorino.org" },
    { firstName: "Iside", lastName: "Negrinotti", personalEmail: "i.negrinotti@turismotorino.org", activityEmail: "resguidereale@turismotorino.org" },
    { firstName: "Erika", lastName: "Carosso", personalEmail: "e.carosso@turismotorino.org", activityEmail: "resguide@turismotorino.org" },
    { firstName: "Chiara", lastName: "Varetto", personalEmail: "c.varetto@turismotorino.org", activityEmail: "resguide@turismotorino.org" },
    { firstName: "Iside", lastName: "Negrinotti", personalEmail: "i.negrinotti@turismotorino.org", activityEmail: "resguidereale@turismotorino.org" },
    { firstName: "Claudia", lastName: "Cossano", personalEmail: "c.cossano@turismotorino.org", activityEmail: "resguidereale@turismotorino.org" },
    { firstName: "Benedetta", lastName: "Lovecchio", personalEmail: "b.lovecchio@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Sabrina", lastName: "Marretta", personalEmail: "s.marretta@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Erika", lastName: "Carosso", personalEmail: "e.carosso@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Elisa", lastName: "Alessi", personalEmail: "e.alessi@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Monica", lastName: "Piras", personalEmail: "m.piras@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Salvatrice", lastName: "Contarino", personalEmail: "s.contarino@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Chiara", lastName: "Varetto", personalEmail: "c.varetto@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Antonella", lastName: "Francolino", personalEmail: "a.francolino@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Manuela", lastName: "Buzio", personalEmail: "m.buzio@turismotorino.org", activityEmail: "info.torino@turismotorino.org" },
    { firstName: "Alessandra", lastName: "Tormena", personalEmail: "a.tormena@turismotorino.org", activityEmail: "promozione@turismotorino.org" },
    { firstName: "Roberta", lastName: "Rossetti", personalEmail: "r.rossetti@turismotorino.org", activityEmail: "promozione@turismotorino.org" },
    { firstName: "Alberto", lastName: "Surico", personalEmail: "a.surico@turismotorino.org", activityEmail: "promozione@turismotorino.org" },
    { firstName: "Veronica", lastName: "Rossi", personalEmail: "v.rossi@turismotorino.org", activityEmail: "promozione@turismotorino.org" },
    { firstName: "Elisa", lastName: "Pilot", personalEmail: "e.pilot@turismotorino.org"},
    { firstName: "Roberta", lastName: "Tealdi", personalEmail: "r.tealdi@turismotorino.org"},
    { firstName: "Grazia", lastName: "Taccetta", personalEmail: "g.taccetta@turismotorino.org"},
    { firstName: "Cristina", lastName: "Cerutti", personalEmail: "c.cerutti@turismotorino.org"},
    { firstName: "Silvia", lastName: "Lanza", personalEmail: "s.lanza@turismotorino.org"},
    { firstName: "Federica", lastName: "Borini", personalEmail: "f.borini@turismotorino.org"},
    { firstName: "Sara", lastName: "Solera", personalEmail: "s.solera@turismotorino.org"},
    { firstName: "Raffaella", lastName: "Brizzi", personalEmail: "r.brizzi@turismotorino.org"},
    { firstName: "Elena Adele", lastName: "Zappia", personalEmail: "e.zappia@turismotorino.org"},
    { firstName: "Devid", lastName: "Perelli", personalEmail: "d.perelli@turismotorino.org"},
    { firstName: "Francesca", lastName: "Sibilla", personalEmail: "f.sibilla@turismotorino.org" },
    { firstName: "Nadia", lastName: "Stara", personalEmail: "n.stara@turismotorino.org" },
    { firstName: "Valeria", lastName: "De Marchi", personalEmail: "v.demarchi@turismotorino.org" },
    { firstName: "Federica", lastName: "Gai", personalEmail: "f.gai@turismotorino.org" },
    { firstName: "Cristina", lastName: "Paolasso", personalEmail: "c.paolasso@turismotorino.org" },
    { firstName: "Paola", lastName: "Lamborghini", personalEmail: "p.lamborghini@turismotorino.org" },
    { firstName: "Giada", lastName: "Armando", personalEmail: "g.armando@turismotorino.org" },
    { firstName: "Mariangela", lastName: "Chiapperini", personalEmail: "m.chiapperini@turismotorino.org" },
    { firstName: "Elena", lastName: "Bruson", personalEmail: "e.bruson@turismotorino.org" },
    { firstName: "Chiara", lastName: "Benevenuta", personalEmail: "c.benevenuta@turismotorino.org" },
    { firstName: "Arianna", lastName: "Cheik", personalEmail: "a.cheik@turismotorino.org" },
    { firstName: "Monia", lastName: "Valle", personalEmail: "m.valle@turismotorino.org" },
    { firstName: "Simona", lastName: "Russo", personalEmail: "s.russo@turismotorino.org" },
    { firstName: "Luella", lastName: "Angioni", personalEmail: "l.angioni@turismotorino.org" },
    { firstName: "Barbara", lastName: "Scalarone", personalEmail: "b.scalarone@turismotorino.org"},
    { firstName: "Manuela", lastName: "Gai", personalEmail: "m.gai@turismotorino.org" },
    { firstName: "Mirella", lastName: "Di Rago", personalEmail: "m.dirago@turismotorino.org" },
    { firstName: "Herik", lastName: "Gottero", personalEmail: "h.gottero@turismotorino.org" },
    { firstName: "Giovanna", lastName: "Barraco", personalEmail: "g.barraco@turismotorino.org" },
    { firstName: "Cristina", lastName: "Cerutti", personalEmail: "c.cerutti@turismotorino.org" },
    { firstName: "Veronica", lastName: "Rossi", personalEmail: "v.rossi@turismotorino.org" },
    { firstName: "Antonella", lastName: "D'amore", personalEmail: "a.damore@turismotorino.org" },
    { firstName: "Marcella", lastName: "Gaspardone", personalEmail: "m.gaspardone@turismotorino.org" },
    { firstName: "Elisa", lastName: "Saporiti", personalEmail: "e.saporiti@turismotorino.org" },
    { firstName: "Mirella", lastName: "Adriano", personalEmail: "m.adriano@turismotorino.org" },
    { firstName: "Nicoletta", lastName: "Paternò", personalEmail: "n.paterno@turismotorino.org" },
    { firstName: "Alessandro", lastName: "Paralovo", personalEmail: "a.paralovo@turismotorino.org" },
    { firstName: "Sara", lastName: "Fiorentino", personalEmail: "s.fiorentino@turismotorino.org" },
    { firstName: "Gloria", lastName: "Faccio", personalEmail: "g.faccio@turismotorino.org" },
    { firstName: "Laura", lastName: "Rosso", personalEmail: "l.rosso@turismotorino.org" },
    { firstName: "Dario", lastName: "Negro", personalEmail: "d.negno@turismotorino.org"},
    { firstName: "Lorenzo",lastName:"Mortera", personalEmail: "l.mortera@turismotorino.org", activityEmail: "info.claviere@turismotorino.org" },
    { firstName: "Lucia", lastName: "Mancuso", personalEmail:"l.mancuso@turismotorino.org" },
    {activityEmail: "scolastico@turismotorino.org"},
    {activityEmail: "convention@turismotorino.org"},
    {activityEmail: "s.lanza@turismotorino.org"},
    {activityEmail: "webteam@turismotorino.org"},
];

export const contactsAssegna = [
    {activityEmail: "info.bardonecchia@turismotorino.org"},
    {activityEmail: "info.cesana@turismotorino.org"},
    {activityEmail: "info.claviere@turismotorino.org"},
    {activityEmail: "info.ivrea@turismotorino.org"},
    {activityEmail: "info.pinerolo@turismotorino.org"},
    {activityEmail: "info.pragelato@turismotorino.org"},
    {activityEmail: "info.sauze@turismotorino.org"},
    {activityEmail: "info.sestriere@turismotorino.org"},
    {activityEmail: "info.susa@turismotorino.org"},
    {activityEmail: "info.torino@turismotorino.org"},
    {activityEmail: "scolastico@turismotorino.org"},

    {activityEmail: "info.desk@turismotorino.org"},
    {activityEmail: "reshotel@turismotorino.org"},
    {activityEmail: "shop@turismotorino.org"},
    {activityEmail: "resguide@turismotorino.org"},
    {activityEmail: "promozione@turismotorino.org"},
    {activityEmail: "convention@turismotorino.org"},
    {activityEmail: "s.lanza@turismotorino.org"},
    {activityEmail: "c.cerutti@turismotorino.org"},
    {activityEmail: "a.paralovo@turismotorino.org"},
    {activityEmail: "s.fiorentino@turismotorino.org "},
    {activityEmail: "n.paterno@turismotorino.org"},
    {activityEmail: "f.sibilla@turismotorino.org"},
    {activityEmail: "f.borini@turismotorino.org"},
];

export const firme = [
    { sede: "Torino", nome: "b.lovecchio - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "b.lovecchio - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "c.varetto - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "c.varetto - resguide", firma: "resguide@turismotorino.org" },
    { sede: "Torino", nome: "c.varetto - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "e.alessi - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "e.alessi - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "e.carosso - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "e.carosso - resguide", firma: "resguide@turismotorino.org" },
    { sede: "Torino", nome: "e.carosso - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "s.contarino - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "s.contarino - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "g.barraco - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "m.buzio - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "m.buzio - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "m.buzio - shop", firma: "shop@turismotorino.org" },
    { sede: "Torino", nome: "m.piras - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "m.piras - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "m.piras - shop", firma: "shop@turismotorino.org" },
    { sede: "Torino", nome: "Mice", firma: "convention@turismotorino.org" },
    { sede: "Torino", nome: "Promozione", firma: "promozione@turismotorino.org" },
    { sede: "Torino", nome: "s.marretta - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "s.marretta - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Torino", nome: "l.mancuso - contact", firma: "info.torino@turismotorino.org" },
    { sede: "Torino", nome: "l.mancuso - scolastico", firma: "scolastico@turismotorino.org" },
    { sede: "Bardonecchia", nome: "a.timon - bardonecchia", firma: "info.bardonecchia@turismotorino.org" },
    { sede: "Bardonecchia", nome: "g.callet - bardonecchia", firma: "info.bardonecchia@turismotorino.org" },
    { sede: "Bardonecchia", nome: "r.deyme - bardonecchia", firma: "info.bardonecchia@turismotorino.org" },
    { sede: "Cesana T.se", nome: "a.milia - cesana", firma: "info.cesana@turismotorino.org" },
    { sede: "Cesana T.se", nome: "b.zacchero - cesana", firma: "info.cesana@turismotorino.org" },
    { sede: "Claviere", nome: "l.rivetti - claviere", firma: "info.claviere@turismotorino.org" },
    { sede: "Claviere", nome: "l.mortera - claviere", firma: "info.claviere@turismotorino.org" },
    { sede: "Ivrea", nome: "c.cossano - ivrea", firma: "info.ivrea@turismotorino.org" },
    { sede: "Ivrea", nome: "i.negrinotti - ivrea", firma: "info.ivrea@turismotorino.org" },
    { sede: "Pinerolo", nome: "d.benech - pinerolo", firma: "info.pinerolo@turismotorino.org" },
    { sede: "Pinerolo", nome: "g.armando - pinerolo", firma: "info.pinerolo@turismotorino.org" },
    { sede: "Pinerolo", nome: "m.buzio - pinerolo", firma: "info.pinerolo@turismotorino.org" },
    { sede: "Pragelato", nome: "a.rostan - pragelato", firma: "info.pragelato@turismotorino.org" },
    { sede: "Pragelato", nome: "m.berton - pragelato", firma: "info.pragelato@turismotorino.org" },
    { sede: "Pragelato", nome: "m.blanc - pragelato", firma: "info.pragelato@turismotorino.org" },
    { sede: "Sauze d'Oulx", nome: "c.eydallin - sauze", firma: "info.sauze@turismotorino.org" },
    { sede: "Sauze d'Oulx", nome: "f.perron - sauze", firma: "info.sauze@turismotorino.org" },
    { sede: "Sestriere", nome: "a.valsesia - sestriere", firma: "info.sestriere@turismotorino.org" },
    { sede: "Sestriere", nome: "s.crespi - sestriere", firma: "info.sestriere@turismotorino.org" },
    { sede: "Susa", nome: "s.pecchenino - susa", firma: "info.susa@turismotorino.org" },
    { sede: "Susa", nome: "s.sibille - susa", firma: "info.susa@turismotorino.org" }
];

export const emailVolontari = [
    "volontariverditoyou@gmail.com",
    "volontariportanuovatoyou@gmail.com",
    "volontaricastellotoyou@gmail.com"
    ];

export const nazioni = [
    {
        "Name": "-",
        "ISO-2": "-"
    },
    {
        "Name": "Andorra",
        "ISO-2": "AD"
    },
    {
        "Name": "Emirati Arabi Uniti",
        "ISO-2": "AE"
    },
    {
        "Name": "Afghanistan",
        "ISO-2": "AF"
    },
    {
        "Name": "Antigua e Barbuda",
        "ISO-2": "AG"
    },
    {
        "Name": "Anguilla",
        "ISO-2": "AI"
    },
    {
        "Name": "Albania",
        "ISO-2": "AL"
    },
    {
        "Name": "Armenia",
        "ISO-2": "AM"
    },
    {
        "Name": "Angola",
        "ISO-2": "AO"
    },
    {
        "Name": "Antartide",
        "ISO-2": "AQ"
    },
    {
        "Name": "Argentina",
        "ISO-2": "AR"
    },
    {
        "Name": "Austria",
        "ISO-2": "AT"
    },
    {
        "Name": "Australia",
        "ISO-2": "AU"
    },
    {
        "Name": "Aruba",
        "ISO-2": "AW"
    },
    {
        "Name": "Isole Aland",
        "ISO-2": "AX"
    },
    {
        "Name": "Azerbaigian",
        "ISO-2": "AZ"
    },
    {
        "Name": "Bosnia Erzegovina",
        "ISO-2": "BA"
    },
    {
        "Name": "Barbados",
        "ISO-2": "BB"
    },
    {
        "Name": "Bangladesh",
        "ISO-2": "BD"
    },
    {
        "Name": "Belgio",
        "ISO-2": "BE"
    },
    {
        "Name": "Burkina Faso",
        "ISO-2": "BF"
    },
    {
        "Name": "Bulgaria",
        "ISO-2": "BG"
    },
    {
        "Name": "Bahrain",
        "ISO-2": "BH"
    },
    {
        "Name": "Burundi",
        "ISO-2": "BI"
    },
    {
        "Name": "Benin",
        "ISO-2": "BJ"
    },
    {
        "Name": "Saint Barthélemy",
        "ISO-2": "BL"
    },
    {
        "Name": "Bermuda",
        "ISO-2": "BM"
    },
    {
        "Name": "Sultanato del Brunei",
        "ISO-2": "BN"
    },
    {
        "Name": "Bolivia",
        "ISO-2": "BO"
    },
    {
        "Name": "BOSNIA ERZEGOVINA",
        "ISO-2": "BOSNIA"
    },
    {
        "Name": "Bonaire, Sint Eustatius e Saba",
        "ISO-2": "BQ"
    },
    {
        "Name": "Brasile",
        "ISO-2": "BR"
    },
    {
        "Name": "Bahamas",
        "ISO-2": "BS"
    },
    {
        "Name": "Bhutan",
        "ISO-2": "BT"
    },
    {
        "Name": "Isola Bouvet",
        "ISO-2": "BV"
    },
    {
        "Name": "Botswana",
        "ISO-2": "BW"
    },
    {
        "Name": "Bosnia",
        "ISO-2": "BX"
    },
    {
        "Name": "Bielorussia",
        "ISO-2": "BY"
    },
    {
        "Name": "Belize",
        "ISO-2": "BZ"
    },
    {
        "Name": "Canada",
        "ISO-2": "CA"
    },
    {
        "Name": "Isole Cocos",
        "ISO-2": "CC"
    },
    {
        "Name": "Congo, Repubblica Democratica del",
        "ISO-2": "CD"
    },
    {
        "Name": "Repubblica Centrafricana",
        "ISO-2": "CF"
    },
    {
        "Name": "Congo",
        "ISO-2": "CG"
    },
    {
        "Name": "Svizzera",
        "ISO-2": "CH"
    },
    {
        "Name": "Costa d'Avorio",
        "ISO-2": "CI"
    },
    {
        "Name": "Isole Cook",
        "ISO-2": "CK"
    },
    {
        "Name": "Cile",
        "ISO-2": "CL"
    },
    {
        "Name": "Camerun",
        "ISO-2": "CM"
    },
    {
        "Name": "Cina",
        "ISO-2": "CN"
    },
    {
        "Name": "Colombia",
        "ISO-2": "CO"
    },
    {
        "Name": "Costa Rica",
        "ISO-2": "CR"
    },
    {
        "Name": "Cuba",
        "ISO-2": "CU"
    },
    {
        "Name": "Capo Verde",
        "ISO-2": "CV"
    },
    {
        "Name": "Curaçao",
        "ISO-2": "CW"
    },
    {
        "Name": "Isola di Natale",
        "ISO-2": "CX"
    },
    {
        "Name": "Cipro",
        "ISO-2": "CY"
    },
    {
        "Name": "Cechia",
        "ISO-2": "CZ"
    },
    {
        "Name": "Germania",
        "ISO-2": "DE"
    },
    {
        "Name": "Gibuti",
        "ISO-2": "DJ"
    },
    {
        "Name": "Danimarca",
        "ISO-2": "DK"
    },
    {
        "Name": "Dominica",
        "ISO-2": "DM"
    },
    {
        "Name": "Repubblica Dominicana",
        "ISO-2": "DO"
    },
    {
        "Name": "Algeria",
        "ISO-2": "DZ"
    },
    {
        "Name": "Ecuador",
        "ISO-2": "EC"
    },
    {
        "Name": "Estonia",
        "ISO-2": "EE"
    },
    {
        "Name": "Egitto",
        "ISO-2": "EG"
    },
    {
        "Name": "Sahara Occidentale",
        "ISO-2": "EH"
    },
    {
        "Name": "Eritrea",
        "ISO-2": "ER"
    },
    {
        "Name": "Spagna",
        "ISO-2": "ES"
    },
    {
        "Name": "Etiopia",
        "ISO-2": "ET"
    },
    {
        "Name": "Finlandia",
        "ISO-2": "FI"
    },
    {
        "Name": "Isole Figi",
        "ISO-2": "FJ"
    },
    {
        "Name": "Isole Falkland",
        "ISO-2": "FK"
    },
    {
        "Name": "Isole Far Oer",
        "ISO-2": "FO"
    },
    {
        "Name": "Francia",
        "ISO-2": "FR"
    },
    {
        "Name": "Gabon",
        "ISO-2": "GA"
    },
    {
        "Name": "Regno Unito",
        "ISO-2": "GB"
    },
    {
        "Name": "Grenada",
        "ISO-2": "GD"
    },
    {
        "Name": "Georgia",
        "ISO-2": "GE"
    },
    {
        "Name": "Guyana Francese",
        "ISO-2": "GF"
    },
    {
        "Name": "Guernsey",
        "ISO-2": "GG"
    },
    {
        "Name": "Ghana",
        "ISO-2": "GH"
    },
    {
        "Name": "Gibilterra",
        "ISO-2": "GI"
    },
    {
        "Name": "Groenlandia",
        "ISO-2": "GL"
    },
    {
        "Name": "Gambia",
        "ISO-2": "GM"
    },
    {
        "Name": "Guinea",
        "ISO-2": "GN"
    },
    {
        "Name": "Guadalupa",
        "ISO-2": "GP"
    },
    {
        "Name": "Guinea Equatoriale",
        "ISO-2": "GQ"
    },
    {
        "Name": "Grecia",
        "ISO-2": "GR"
    },
    {
        "Name": "Georgia del Sud e Isole Sandwich meridionali",
        "ISO-2": "GS"
    },
    {
        "Name": "Guatemala",
        "ISO-2": "GT"
    },
    {
        "Name": "Guinea-Bissau",
        "ISO-2": "GW"
    },
    {
        "Name": "Guyana",
        "ISO-2": "GY"
    },
    {
        "Name": "Isola Heard e Isole McDonald",
        "ISO-2": "HM"
    },
    {
        "Name": "Honduras",
        "ISO-2": "HN"
    },
    {
        "Name": "Croazia",
        "ISO-2": "HR"
    },
    {
        "Name": "Haiti",
        "ISO-2": "HT"
    },
    {
        "Name": "Ungheria",
        "ISO-2": "HU"
    },
    {
        "Name": "Indonesia",
        "ISO-2": "ID"
    },
    {
        "Name": "Irlanda",
        "ISO-2": "IE"
    },
    {
        "Name": "Israele",
        "ISO-2": "IL"
    },
    {
        "Name": "Isola di Man",
        "ISO-2": "IM"
    },
    {
        "Name": "India",
        "ISO-2": "IN"
    },
    {
        "Name": "Territorio britannico dell'Oceano Indiano",
        "ISO-2": "IO"
    },
    {
        "Name": "Iraq",
        "ISO-2": "IQ"
    },
    {
        "Name": "Islanda",
        "ISO-2": "IS"
    },
    {
        "Name": "Italia",
        "ISO-2": "IT"
    },
    {
        "Name": "Iran",
        "ISO-2": "IX"
    },
    {
        "Name": "Jersey",
        "ISO-2": "JE"
    },
    {
        "Name": "Giamaica",
        "ISO-2": "JM"
    },
    {
        "Name": "Giordania",
        "ISO-2": "JO"
    },
    {
        "Name": "Giappone",
        "ISO-2": "JP"
    },
    {
        "Name": "Kenia",
        "ISO-2": "KE"
    },
    {
        "Name": "Kirghizistan",
        "ISO-2": "KG"
    },
    {
        "Name": "Cambogia",
        "ISO-2": "KH"
    },
    {
        "Name": "Kiribati",
        "ISO-2": "KI"
    },
    {
        "Name": "Comore",
        "ISO-2": "KM"
    },
    {
        "Name": "Saint Kitts e Nevis",
        "ISO-2": "KN"
    },
    {
        "Name": "Corea",
        "ISO-2": "KR"
    },
    {
        "Name": "Corea del Sud",
        "ISO-2": "KS"
    },
    {
        "Name": "Kuwait",
        "ISO-2": "KW"
    },
    {
        "Name": "Isole Cayman",
        "ISO-2": "KY"
    },
    {
        "Name": "Kazakistan",
        "ISO-2": "KZ"
    },
    {
        "Name": "Laos",
        "ISO-2": "LA"
    },
    {
        "Name": "Libano",
        "ISO-2": "LB"
    },
    {
        "Name": "Santa Lucia",
        "ISO-2": "LC"
    },
    {
        "Name": "Liechtenstein",
        "ISO-2": "LI"
    },
    {
        "Name": "Sri Lanka",
        "ISO-2": "LK"
    },
    {
        "Name": "Liberia",
        "ISO-2": "LR"
    },
    {
        "Name": "Lesotho",
        "ISO-2": "LS"
    },
    {
        "Name": "Lituania",
        "ISO-2": "LT"
    },
    {
        "Name": "Lussemburgo",
        "ISO-2": "LU"
    },
    {
        "Name": "Lettonia",
        "ISO-2": "LV"
    },
    {
        "Name": "Libia",
        "ISO-2": "LY"
    },
    {
        "Name": "Marocco",
        "ISO-2": "MA"
    },
    {
        "Name": "Monaco",
        "ISO-2": "MC"
    },
    {
        "Name": "Moldavia",
        "ISO-2": "MD"
    },
    {
        "Name": "Montenegro",
        "ISO-2": "ME"
    },
    {
        "Name": "Saint-Martin (parte francese)",
        "ISO-2": "MF"
    },
    {
        "Name": "Madagascar",
        "ISO-2": "MG"
    },
    {
        "Name": "Macedonia",
        "ISO-2": "MK"
    },
    {
        "Name": "Mali",
        "ISO-2": "ML"
    },
    {
        "Name": "Myanmar",
        "ISO-2": "MM"
    },
    {
        "Name": "Mongolia",
        "ISO-2": "MN"
    },
    {
        "Name": "Macao",
        "ISO-2": "MO"
    },
    {
        "Name": "Martinica",
        "ISO-2": "MQ"
    },
    {
        "Name": "Mauritania",
        "ISO-2": "MR"
    },
    {
        "Name": "Montserrat",
        "ISO-2": "MS"
    },
    {
        "Name": "Malta",
        "ISO-2": "MT"
    },
    {
        "Name": "Mauritius",
        "ISO-2": "MU"
    },
    {
        "Name": "Maldive",
        "ISO-2": "MV"
    },
    {
        "Name": "Malawi",
        "ISO-2": "MW"
    },
    {
        "Name": "Messico",
        "ISO-2": "MX"
    },
    {
        "Name": "Malaysia",
        "ISO-2": "MY"
    },
    {
        "Name": "Mozambico",
        "ISO-2": "MZ"
    },
    {
        "Name": "Namibia",
        "ISO-2": "NA"
    },
    {
        "Name": "Nuova Caledonia",
        "ISO-2": "NC"
    },
    {
        "Name": "Niger",
        "ISO-2": "NE"
    },
    {
        "Name": "Isola Norfolk",
        "ISO-2": "NF"
    },
    {
        "Name": "Nigeria",
        "ISO-2": "NG"
    },
    {
        "Name": "Nicaragua",
        "ISO-2": "NI"
    },
    {
        "Name": "Olanda",
        "ISO-2": "NL"
    },
    {
        "Name": "Norvegia",
        "ISO-2": "NO"
    },
    {
        "Name": "Nepal",
        "ISO-2": "NP"
    },
    {
        "Name": "Nauru",
        "ISO-2": "NR"
    },
    {
        "Name": "Niue",
        "ISO-2": "NU"
    },
    {
        "Name": "Nuova Zelanda",
        "ISO-2": "NZ"
    },
    {
        "Name": "Oman",
        "ISO-2": "OM"
    },
    {
        "Name": "Panama",
        "ISO-2": "PA"
    },
    {
        "Name": "Perù",
        "ISO-2": "PE"
    },
    {
        "Name": "Polinesia Francese",
        "ISO-2": "PF"
    },
    {
        "Name": "Papua Nuova Guinea",
        "ISO-2": "PG"
    },
    {
        "Name": "Filippine",
        "ISO-2": "PH"
    },
    {
        "Name": "Pakistan",
        "ISO-2": "PK"
    },
    {
        "Name": "Polonia",
        "ISO-2": "PL"
    },
    {
        "Name": "Saint-Pierre e Miquelon",
        "ISO-2": "PM"
    },
    {
        "Name": "Pitcairn",
        "ISO-2": "PN"
    },
    {
        "Name": "Porto Rico",
        "ISO-2": "PR"
    },
    {
        "Name": "Palestina",
        "ISO-2": "PS"
    },
    {
        "Name": "Portogallo",
        "ISO-2": "PT"
    },
    {
        "Name": "Paraguay",
        "ISO-2": "PY"
    },
    {
        "Name": "Qatar",
        "ISO-2": "QA"
    },
    {
        "Name": "Ruanda",
        "ISO-2": "RD"
    },
    {
        "Name": "Reunion",
        "ISO-2": "RE"
    },
    {
        "Name": "Romania",
        "ISO-2": "RO"
    },
    {
        "Name": "Serbia",
        "ISO-2": "RS"
    },
    {
        "Name": "Federazione Russa",
        "ISO-2": "RU"
    },
    {
        "Name": "Rwanda",
        "ISO-2": "RW"
    },
    {
        "Name": "Arabia Saudita",
        "ISO-2": "SA"
    },
    {
        "Name": "Isole Salomone",
        "ISO-2": "SB"
    },
    {
        "Name": "Seychelles",
        "ISO-2": "SC"
    },
    {
        "Name": "Sudan",
        "ISO-2": "SD"
    },
    {
        "Name": "Svezia",
        "ISO-2": "SE"
    },
    {
        "Name": "Singapore",
        "ISO-2": "SG"
    },
    {
        "Name": "Sant'Elena, Ascensione e Tristan da Cunha",
        "ISO-2": "SH"
    },
    {
        "Name": "Slovenia",
        "ISO-2": "SI"
    },
    {
        "Name": "Svalbard e Jan Mayen",
        "ISO-2": "SJ"
    },
    {
        "Name": "Slovacchia",
        "ISO-2": "SK"
    },
    {
        "Name": "Sierra Leone",
        "ISO-2": "SL"
    },
    {
        "Name": "San Marino",
        "ISO-2": "SM"
    },
    {
        "Name": "Senegal",
        "ISO-2": "SN"
    },
    {
        "Name": "Somalia",
        "ISO-2": "SO"
    },
    {
        "Name": "Suriname",
        "ISO-2": "SR"
    },
    {
        "Name": "Sudan del Sud",
        "ISO-2": "SS"
    },
    {
        "Name": "São Tomé e Principe",
        "ISO-2": "ST"
    },
    {
        "Name": "El Salvador",
        "ISO-2": "SV"
    },
    {
        "Name": "Sint Maarten (parte olandese)",
        "ISO-2": "SX"
    },
    {
        "Name": "Eswatini",
        "ISO-2": "SZ"
    },
    {
        "Name": "Isole Turks e Caicos",
        "ISO-2": "TC"
    },
    {
        "Name": "Ciad",
        "ISO-2": "TD"
    },
    {
        "Name": "Territori Francesi del Sud",
        "ISO-2": "TF"
    },
    {
        "Name": "Togo",
        "ISO-2": "TG"
    },
    {
        "Name": "Thailandia",
        "ISO-2": "TH"
    },
    {
        "Name": "Tagikistan",
        "ISO-2": "TJ"
    },
    {
        "Name": "Tokelau",
        "ISO-2": "TK"
    },
    {
        "Name": "Timor-Leste",
        "ISO-2": "TL"
    },
    {
        "Name": "Turkmenistan",
        "ISO-2": "TM"
    },
    {
        "Name": "Tunisia",
        "ISO-2": "TN"
    },
    {
        "Name": "Tonga",
        "ISO-2": "TO"
    },
    {
        "Name": "Turchia",
        "ISO-2": "TR"
    },
    {
        "Name": "Trinidad e Tobago",
        "ISO-2": "TT"
    },
    {
        "Name": "Tuvalu",
        "ISO-2": "TV"
    },
    {
        "Name": "Taiwan",
        "ISO-2": "TW"
    },
    {
        "Name": "Tanzania",
        "ISO-2": "TZ"
    },
    {
        "Name": "Ucraina",
        "ISO-2": "UA"
    },
    {
        "Name": "Uganda",
        "ISO-2": "UG"
    },
    {
        "Name": "Stati Uniti",
        "ISO-2": "US"
    },
    {
        "Name": "Uruguay",
        "ISO-2": "UY"
    },
    {
        "Name": "Uzbekistan",
        "ISO-2": "UZ"
    },
    {
        "Name": "Città del Vaticano",
        "ISO-2": "VA"
    },
    {
        "Name": "Saint Vincent e Grenadine",
        "ISO-2": "VC"
    },
    {
        "Name": "Venezuela",
        "ISO-2": "VE"
    },
    {
        "Name": "Isole Vergini, britanniche",
        "ISO-2": "VG"
    },
    {
        "Name": "Vietnam",
        "ISO-2": "VN"
    },
    {
        "Name": "Vanuatu",
        "ISO-2": "VU"
    },
    {
        "Name": "Wallis e Futuna",
        "ISO-2": "WF"
    },
    {
        "Name": "Samoa",
        "ISO-2": "WS"
    },
    {
        "Name": "Kosovo",
        "ISO-2": "XK"
    },
    {
        "Name": "Yemen",
        "ISO-2": "YE"
    },
    {
        "Name": "Mayotte",
        "ISO-2": "YT"
    },
    {
        "Name": "Sudafrica",
        "ISO-2": "ZA"
    },
    {
        "Name": "Zambia",
        "ISO-2": "ZM"
    },
    {
        "Name": "Zimbabwe",
        "ISO-2": "ZW"
    },
    {
        "Name": "Siria",
        "ISO-2": "SY"
    },
];