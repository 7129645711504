import React, { useState } from 'react';

const QuestionBlock = ({ blockNumber }) => {
    const [isInputEnabled, setInputEnabled] = useState(false);

    const handleMouseOver = (e) => {
        setInputEnabled(true);
    };

    const handleMouseDown = (e) => {
        e.target.setAttribute('autocomplete', 'off');
    };

    return (
        <div data-question={blockNumber} className="singleQuestion">
            <div className="row" style={{maxHeight:"100px"}}>
                <div className="col-md-6 mt-3">
                    <label htmlFor={"validationTdR" + blockNumber} className="form-label">Tipo di richiesta*</label>
                    <select className="form-select" aria-label="Canale*" name="" id={"validationTdR" + blockNumber}>
                        <option selected="true" style={{display: "none"}} value="">Seleziona il tipo di richiesta
                        </option>
                        <option value="resources">Risorsa</option>
                        <option value="events">Evento</option>
                        <option value="products">Prodotto</option>
                        <option value="tables">Tabella</option>
                        <option value="altro">Altro</option>
                    </select>
                    <div className="valid-feedback">Ottimo lavoro!</div>
                    <div className="invalid-feedback">Seleziona un tipo di richiesta</div>
                </div>
                <div className="col-md-6 mt-3">
                    <label htmlFor={"validationRS" + blockNumber} className="form-label">Richiesta specifica*</label>
                    <input type="text" className="form-control" id={"validationRS" + blockNumber}
                           list={"datalist" + blockNumber} autoComplete={Math.random().toString()}
                           onMouseOver={handleMouseOver} onMouseDown={handleMouseDown} disabled={!isInputEnabled}/>
                    <datalist id={"datalist" + blockNumber}></datalist>
                    <div className="valid-feedback">Ottimo lavoro!</div>
                    <div className="invalid-feedback">Seleziona una richiesta specifica</div>
                </div>
            </div>
            <div className="col-md-12 mt-3">
                <label htmlFor={"validationIndirizzo" + blockNumber} className="form-label">Note</label>
                <div className="form-floating">
                    <textarea className="form-control" placeholder="Leave a comment here"
                              id={"floatingTextarea" + blockNumber}></textarea>
                    <label htmlFor={"floatingTextarea" + blockNumber}>Indica il motivo della richiesta</label>
                </div>
                <div className="valid-feedback">Ottimo lavoro!</div>
                <div className="invalid-feedback">Inserisci un indirizzo</div>
            </div>
            <div className="form-check form-switch mt-3">
                <input className="form-check-input" type="checkbox" role="switch" id={"closeRequest" + blockNumber}/>
                <label className="form-check-label" htmlFor={"closeRequest" + blockNumber}>Chiudi Richiesta</label>
            </div>
        </div>
    );
};

export default QuestionBlock;