import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from "../../components/Sidebar";
import './TicketsPage.css';
import WebStorageCache from 'web-storage-cache';
import { useNavigate } from 'react-router-dom';
import { getTickets, getToken, getTemplates } from "../../utils/apiTicketing";
import { useLocation } from 'react-router-dom';
import { refreshToken } from "../../utils/directusClient";
import { LoadingContext } from "../../utils/LoadingContext";
import { deleteTicket } from "../../utils/apiTicketing";
import { getSedi } from '../../utils/api-db';

const webStorageCache = new WebStorageCache();

const TicketsPage = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const [tickets, setTickets] = useState([]);
    const [sidebarWidth, setSidebarWidth] = useState(window.innerWidth <= 800 ? '16.6667%' : '25%');
    const navigate = useNavigate();
    const location = useLocation();
    let from;
    if (location.state) {
        ({ from } = location.state);
    }

    const openEditPage = (id) => {
        sessionStorage.setItem('editTicketId', id);
        navigate('/modifica-ticket');
    };

    const deleteRichiesta = async (id) => {
        await deleteTicket(id);
        window.location.reload();
    };

    useEffect(() => {
        const fetchData = async () => {
            await refreshToken();
            if (!localStorage.getItem('isLoggedIn') || !webStorageCache.get('salesforceId')) {
                navigate('/login');
                return;
            }
            await getSedi();
            setIsLoading(false);

            const uid = webStorageCache.get('salesforceId');
            let tickets = [];
            let nTickets = localStorage.getItem('nTickets') || 0;
            let nTicketsBozze = localStorage.getItem('nTicketsBozze') || 0;
            if (from === 'Bozze') {
                if (nTicketsBozze > 0) {
                    tickets = JSON.parse(localStorage.getItem('ticketsBozze') || '[]');
                } else {
                    tickets = [];
                }
            } else {
                if (nTickets > 0) {
                    tickets = JSON.parse(localStorage.getItem('tickets') || '[]');
                } else {
                    tickets = [];
                }
            }

            let htmlStrings = tickets.map((record, index) => {
                const isDraft = record.bozza__c === true;
                const draftClass = isDraft ? 'table-danger' : '';

                let sedi = JSON.parse(localStorage.getItem('sedi'));
                let salesforceId = record.Sede__c;
                let sede = sedi.find(sede => sede.salesforceId === salesforceId);
                let nomeSede = sede ? sede.nome : salesforceId;

                if (record.Stato_Ticket__c !== '0') {
                    return (
                        <tr className={draftClass} key={index}>
                            <th colSpan="1" scope="row">{record.Id}</th>
                            <td colSpan="4">
                                {record.Contact.FirstName || record.Contact.LastName ?
                                    `${record.Contact.FirstName || ''} ${record.Contact.LastName || ''}`.trim() :
                                    'N/A'}
                            </td>
                            <td colSpan="2"><a href={`mailto:${record.email}`} className="link-dark"
                                               style={{fontSize: '0.8rem'}}>{record.Contact.Email}</a></td>
                            <td colSpan="2">{nomeSede}</td>
                            <td colSpan="2">{record.Data__c}</td>
                            <td style={{display: 'flex', justifyContent: 'center'}}>
                                {/*<a className="btn btn-outline-success" href=""><i className="bi bi-person"></i>{record.Ripeti_per_N__c}</a> */}
                                    <a className="btn btn-outline-warning ms-4" onClick={() => openEditPage(record.Id)}><i
                                        className="bi bi-pen"></i></a>
                                    <a className="btn btn-outline-danger" href="" data-toggle="modal"
                                       data-target={`#confirm-${record.Id}`}><i className="bi bi-trash3"></i></a>
                                <div className="modal fade" id={`confirm-${record.Id}`} aria-labelledby="confirm"
                                     aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="title">Elimina anagrafica</h5>
                                            </div>
                                            <div className="modal-body">
                                                Sei sicuro di voler procedere?
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-outline-warning"
                                                        data-dismiss="modal">Annulla
                                                </button>
                                                <button type="button" className="btn btn-outline-danger"
                                                        data-dismiss="modal"
                                                        onClick={() => deleteRichiesta(record.Id)}>Elimina
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr className={draftClass} key={index}>
                            <th colSpan="1" scope="row">{record.Id}</th>
                            <td colSpan="4">Ticket Anonimo</td>
                            <td colSpan="2">Assente</td>
                            <td colSpan="2">{nomeSede}</td>
                            <td colSpan="2">{record.Data__c}</td>
                            <td style={{display: 'flex', justifyContent: 'center'}}>
                                {/* <a className="btn btn-outline-success" href=""><i className="bi bi-person"></i>{record.Ripeti_per_N__c}</a> */}
                                <a className="btn btn-outline-warning ms-4" onClick={() => openEditPage(record.Id)}><i
                                    className="bi bi-pen"></i></a>
                                <a className="btn btn-outline-danger" href="" data-toggle="modal"
                                   data-target={`#confirm-${record.Id}`}><i className="bi bi-trash3"></i></a>
                                <div className="modal fade" id={`confirm-${record.Id}`} aria-labelledby="confirm"
                                     aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="title">Elimina anagrafica</h5>
                                            </div>
                                            <div className="modal-body">
                                                Sei sicuro di voler procedere?
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-outline-warning"
                                                        data-dismiss="modal">Annulla
                                                </button>
                                                <button type="button" className="btn btn-outline-danger"
                                                        data-dismiss="modal"
                                                        onClick={() => deleteRichiesta(record.Id)}>Elimina
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                }
            });
            setTickets(htmlStrings);
        };

        fetchData();
    }, [navigate, from]);


    return (
        <main>
            <Helmet>
                <title>Ticket - Turismo Torino e Provincia</title>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta charSet="utf-8" />
                <meta name="robots" content="noindex, nofollow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#5ac4ed" />
                <link href="favicon.png" rel="icon" type="image/png" />
                <link href="https://fonts.cdnfonts.com/css/switzer" rel="stylesheet" />
                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
                    integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
                    crossOrigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js"
                    integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
                    crossOrigin="anonymous"></script>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js"
                    integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
                    crossOrigin="anonymous"></script>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet"
                    integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                    crossOrigin="anonymous" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
                    crossOrigin="anonymous"></script>
                <link rel="stylesheet"
                    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"></link>
            </Helmet>
            <div className="container-fluid no-padding-left">
                <div className="row">
                    <Sidebar />
                    <div className="col-md-9 p-4 responsive-margin"
                         style={{marginLeft: sidebarWidth, width: `calc(100% - ${sidebarWidth})`}}>
                        <div className="card mb-4 btn-success no-mob" style={{maxHeight: "70px"}}>
                            <div className="card-body">
                                <div className="row" style={{maxHeight: "50px"}}>
                                    <div className="col fw-bold" style={{maxHeight: "50px"}}>
                                        <p className="fs-4 mb-0"><i
                                            className="bi bi-ticket"></i>{from === 'Bozze' ? 'Bozze' : 'Ticket'}</p>
                                    </div>
                                    <div className="col text-center" style={{maxHeight: "50px"}}>
                                        <p id="value_ticket2" className="fs-4 mb-0"><i
                                            className="bi bi-ticket"></i>
                                            <span className="fs-4 mb-0" id="ticket_number">0</span></p>
                                    </div>
                                    <div className="col text-end" style={{maxHeight: "50px"}}>
                                        <p id="value_ticket3" className="fs-4 mb-0"><i
                                            className="bi bi-ticket"></i>/<i
                                            className="bi bi-person"></i> <span className="fs-4 mb-0"
                                                                                id="ticket_number_bozze">0</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table align-middle table-sm">
                                <thead className="sticky-top">
                                <tr>
                                    <th scope="col" colSpan="1" style={{backgroundColor: "white"}}>ID</th>
                                    <th scope="col" colSpan="4" style={{backgroundColor: "white"}}>Nome e cognome
                                    </th>
                                    <th scope="col" colSpan="2" style={{backgroundColor: "white"}}>E-mail</th>
                                    <th scope="col" colSpan="2" style={{backgroundColor: "white"}}>Sede</th>
                                    <th scope="col" colSpan="2" style={{backgroundColor: "white"}}>Data</th>
                                    <th scope="col" colSpan="2"
                                        style={{backgroundColor: "white", paddingLeft: "86px"}}>Azioni
                                    </th>
                                </tr>
                                </thead>
                                <tbody id="tableBody">
                                {tickets}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default TicketsPage;