const selectedValueMap = {
    resources: 'risorse',
    events: 'eventi',
    products: 'prodotti',
    tables: 'tabelle',
    altro: 'altro'
};

export const handleInputRisorse = (e, selectedIds, setSelectedIds, urlSearch) => {
    if (e.target.id.startsWith('validationRS') && e.target.id !== 'validationRS') {
        let questionNumber = e.target.id.replace('validationRS', '');
        document.getElementById('validationRS' + questionNumber).style.backgroundColor = "#ffffff";
        let selectedValue = document.getElementById('validationTdR' + questionNumber).value || '';
        let inputText = e.target.value || '';
        let uniqueHits = new Set();
        let inputElement = document.getElementById('validationRS' + questionNumber);
        let dataList = document.getElementById('datalist' + questionNumber);

        if (dataList && Array.from(dataList.options).some(option => option.value.trim().toLowerCase() === e.target.value.trim().toLowerCase())) {
            return;
        }

        if (!dataList) {
            dataList = document.createElement('datalist');
            dataList.id = 'datalist' + questionNumber;
            inputElement.appendChild(dataList);
        }
        dataList.innerHTML = '';

        if (selectedValue === 'tables') {
            // Retrieve tables from local storage
            let tables = JSON.parse(localStorage.getItem('tabelle')) || [];
            tables.forEach(table => {
                let textContent = table.id + ' - tabelle';
                if (!uniqueHits.has(textContent)) {
                    uniqueHits.add(textContent);
                    let option = document.createElement('option');
                    option.value = table.denominazione;
                    option.textContent = textContent;
                    dataList.appendChild(option);
                }
            });
            // Add event listener after dataList is populated
            addChangeListener(inputElement, dataList, setSelectedIds);
        } else {
            if (inputText.length < 3) {
                return;
            }
            let body = {
                q: inputText,
                page: 1,
            };
            fetch(urlSearch, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
                .then(response => response.json())
                .then(data => {
                    console.log("Data:");
                    console.log(data);
                    for (let i = 0; i < data.hits.length; i++) {
                        let collection = data.hits[i].collection;
                        if ((selectedValue === '' && (collection === 'risorse' || collection === 'eventi' || collection === 'prodotti')) ||
                            (selectedValueMap[selectedValue] && collection === selectedValueMap[selectedValue])) {
                            if (data.hits[i].tipo !== 'generica') {
                                let id = data.hits[i].id;
                                let idWithNumbersOnly = id;
                                let textContent = idWithNumbersOnly + ' - ' + collection;
                                if (!uniqueHits.has(textContent)) {
                                    uniqueHits.add(textContent);
                                    if (!Array.from(dataList.options).some(option => option.textContent === textContent)) {
                                        let option = document.createElement('option');
                                        option.value = data.hits[i].titolo;
                                        option.textContent = textContent;
                                        dataList.appendChild(option);
                                    }
                                }
                            }
                        }
                    }
                    let page = 2;
                    let maxPages = Math.min(data.totalPages || 1, 10);

                    for (page; page <= maxPages; page++) {
                        body.page = page;
                        fetch(urlSearch, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(body)
                        })
                            .then(response => response.json())
                            .then(data => {
                                for (let i = 0; i < data.hits.length; i++) {
                                    let collection = data.hits[i].collection;
                                    if ((selectedValue === '' && (collection === 'risorse' || collection === 'eventi' || collection === 'prodotti')) ||
                                        (selectedValueMap[selectedValue] && collection === selectedValueMap[selectedValue])) {
                                        if (data.hits[i].tipo !== 'generica') {
                                            let id = data.hits[i].id;
                                            let idWithNumbersOnly = id;
                                            let textContent = idWithNumbersOnly + ' - ' + collection;
                                            if (!uniqueHits.has(textContent)) {
                                                uniqueHits.add(textContent);
                                                if (!Array.from(dataList.options).some(option => option.textContent === textContent)) {
                                                    let option = document.createElement('option');
                                                    option.value = data.hits[i].titolo;
                                                    option.textContent = textContent;
                                                    dataList.appendChild(option);
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                    }
                })
                .then(() => {
                    observeDataList(inputElement, dataList, setSelectedIds);
                });
        }
    }
}

function observeDataList(inputElement, dataList, setSelectedIds) {
    const observer = new MutationObserver(() => {
        if (dataList.options.length > 0) {
            observer.disconnect();
            addChangeListener(inputElement, dataList, setSelectedIds);
        }
    });

    observer.observe(dataList, { childList: true });
}

function addChangeListener(inputElement, dataList, setSelectedIds) {
    inputElement.addEventListener('change', function (e) {
        console.log("dataList.options:");
        let optionsArray = Array.from(dataList.options);
        console.log(optionsArray);

        console.log("dataList.options.value:");
        let values = optionsArray.map(option => option.value);
        console.log(JSON.stringify(values));

        console.log("this.value:");
        console.log("-" + this.value + "-");

        let selectedOption = optionsArray.find(option =>
            option.value.trim().toLowerCase() === this.value.trim().toLowerCase()
        );
        console.log('Selected option:', selectedOption);

        if (selectedOption) {
            let selectedId = selectedOption.textContent.split(' - ')[0];
            let collection = selectedOption.textContent.split(' - ')[1];
            console.log('Selected ID:', selectedId);
            console.log('Collection:', collection);
            let currentSelectedIds = JSON.parse(sessionStorage.getItem('selectedIds')) || {};
            var questionNumber = e.target.id.replace('validationRS', '');
            console.log('Question number:', questionNumber);
            if (selectedId !== currentSelectedIds[questionNumber]) {
                currentSelectedIds[questionNumber] = selectedId;
                sessionStorage.setItem('selectedIds', JSON.stringify(currentSelectedIds));
                setSelectedIds(currentSelectedIds);
                console.log(sessionStorage.getItem('selectedIds'));
            } else {
                console.log('Selected ID is the same as the existing one in selectedIds');
            }
            document.getElementById('validationRS' + questionNumber).style.backgroundColor = "#ccffcc";
            switch (collection) {
                case "risorse":
                    document.getElementById('validationTdR' + questionNumber).value = "resources";
                    break;
                case "eventi":
                    document.getElementById('validationTdR' + questionNumber).value = "events";
                    break;
                case "prodotti":
                    document.getElementById('validationTdR' + questionNumber).value = "products";
                    break;
                case "tabelle":
                    document.getElementById('validationTdR' + questionNumber).value = "tables";
                    break;
                default:
                    document.getElementById('validationTdR' + questionNumber).value = "altro";
                    break;
            }
        }
    });
}