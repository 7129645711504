import React, { useEffect, useState } from 'react';
import WebStorageCache from 'web-storage-cache';
import {refreshToken} from "./directusClient";

const webStorageCache = new WebStorageCache();
const urlSearch = process.env.REACT_APP_API_URL_SEARCH;
const urlTicketing = process.env.REACT_APP_API_URL_TICKET;
const urlDirectus = process.env.REACT_APP_API_URL;

//funzione get sedi
export async function getSedi() {
    await refreshToken();
    var urlSedi = urlTicketing + "sedi";
    var sedi = [];
    fetch(urlSedi, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_STATIC_TOKEN
        }
    })
    .then(response => response.json())
    .then(data => {
        data.forEach(item => {
            sedi.push({ salesforceId: item.salesforce_id, nome: item.nome, temporanea: item.temporanea });
        });
        localStorage.setItem('sedi', JSON.stringify(sedi));
    });
}

export async function getCategorie() {
    await refreshToken();
    var urlCategorie = urlTicketing + "categorie";
    var categorie = [];
    fetch(urlCategorie, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_STATIC_TOKEN
        }
    })
        .then(response => response.json())
        .then(data => {
            data.forEach(item => {
                categorie.push({value: item.nome, label: item.nome});
            });
            localStorage.setItem('categorie', JSON.stringify(categorie));
        });
}

export async function getTabelle(){
    await refreshToken();
    var urlTabelle = urlDirectus+"items/tabelle_export";
    const filter = "?filter[status][_eq]=published";
    const fields = "&fields[]=id&fields[]=denominazione&fields[]=tabella";
    var tabelle = [];
    fetch(urlTabelle+filter+fields, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_STATIC_TOKEN
        }
    })
        .then(response => response.json())
        .then(data => {
            const urlTabelle = urlDirectus+"report/download/";
            //const urlTabelle = "https://cms2.turismotorino.org/report/download/"
            data.data.forEach(item => {
                tabelle.push({id: item.id, denominazione: item.denominazione, tabella: item.tabella, url: urlTabelle+item.id});
            });
            localStorage.setItem('tabelle', JSON.stringify(tabelle));
        });
}

