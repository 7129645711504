// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: 'Switzer', sans-serif;
    font-size: 1rem;
}

body {
    background-color: #f1f1f1;
}

.row {
    height: 100vh;
}

.nav-link.active, .btn-success {
    background-color: #5ac4ed!important;
}

.btn-outline-success {
    border-color: #5ac4ed!important;
    color: #5ac4ed!important;
}

.btn-outline-success:hover {
    background-color: #5ac4ed!important;
    color: #fff!important;
}

.logo-image {
    width: 90%;
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,+BAA+B;IAC/B,wBAAwB;AAC5B;;AAEA;IACI,mCAAmC;IACnC,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB","sourcesContent":["* {\r\n    font-family: 'Switzer', sans-serif;\r\n    font-size: 1rem;\r\n}\r\n\r\nbody {\r\n    background-color: #f1f1f1;\r\n}\r\n\r\n.row {\r\n    height: 100vh;\r\n}\r\n\r\n.nav-link.active, .btn-success {\r\n    background-color: #5ac4ed!important;\r\n}\r\n\r\n.btn-outline-success {\r\n    border-color: #5ac4ed!important;\r\n    color: #5ac4ed!important;\r\n}\r\n\r\n.btn-outline-success:hover {\r\n    background-color: #5ac4ed!important;\r\n    color: #fff!important;\r\n}\r\n\r\n.logo-image {\r\n    width: 90%;\r\n    height: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
