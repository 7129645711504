import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { Dropdown } from 'react-bootstrap';

const TabelleDropdown = () => {
    const tabelle = localStorage.getItem('tabelle') ? JSON.parse(localStorage.getItem('tabelle')) : [];
    const [isCopied, setIsCopied] = useState(Array(tabelle.length).fill(false));
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Allegati
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <div className="card-body">
                        <input
                            type="text"
                            placeholder="Cerca..."
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                            style={{marginLeft: '10px'}}
                        />
                        <table className="table" style={{display: 'block'}}>
                            <thead>
                            <tr>
                                <th>Denominazione</th>
                                <th>Categoria</th>
                                <th>Copia URL</th>
                                <th>Apri URL</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tabelle.filter(tabella => tabella.denominazione && tabella.denominazione.toLowerCase().includes(searchTerm.toLowerCase())).map((tabella, index) => (
                                <tr key={index}>
                                    <td>{tabella.denominazione}</td>
                                    <td>{tabella.tabella}</td>
                                    <td>
                                        <CopyToClipboard text={tabella.url} onCopy={() => {
                                            setIsCopied(prevIsCopied => {
                                                const newIsCopied = Array(tabelle.length).fill(false);
                                                newIsCopied[index] = true;
                                                return newIsCopied;
                                            });
                                        }}>
                                            <button className="btn btn-primary"
                                                    style={{backgroundColor: isCopied[index] ? "green" : null}}>
                                                <FaCopy/> {/* Icona di copia */}
                                            </button>
                                        </CopyToClipboard>
                                    </td>
                                    <td>
                                        <a href={tabella.url} target="_blank" rel="noreferrer"
                                           className="btn btn-secondary">
                                            <BsBoxArrowUpRight/>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        Inserisci gli url nel corpo della mail
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default TabelleDropdown;