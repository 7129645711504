import React, { useEffect, useState } from 'react';
import WebStorageCache from 'web-storage-cache';
import {refreshToken} from "./directusClient";

const webStorageCache = new WebStorageCache();
const urlSearch = process.env.REACT_APP_API_URL_SEARCH;
const urlTicketing = process.env.REACT_APP_API_URL_TICKET;
const urlDirectus = process.env.REACT_APP_API_URL;

//funzione get sedi
export async function getSedi() {
    await refreshToken();
    var urlSedi = urlTicketing + "sedi";
    var sedi = [];
    fetch(urlSedi, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_STATIC_TOKEN
        }
    })
    .then(response => response.json())
    .then(data => {
        data.forEach(item => {
            sedi.push({ salesforceId: item.salesforce_id, nome: item.nome, temporanea: item.temporanea });
        });
        localStorage.setItem('sedi', JSON.stringify(sedi));
    });
}

export async function getCategorie() {
    await refreshToken();
    var urlCategorie = urlTicketing + "categorie";
    var categorie = [];
    fetch(urlCategorie, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_STATIC_TOKEN
        }
    })
        .then(response => response.json())
        .then(data => {
            data.forEach(item => {
                categorie.push({value: item.nome, label: item.nome});
            });
            localStorage.setItem('categorie', JSON.stringify(categorie));
        });
}

export async function getTabelle() {
    await refreshToken();
    const urlTabelle = urlDirectus + "items/tabelle_export";
    const filter = "?filter[status][_eq]=published";
    const fields = "&fields[]=id&fields[]=denominazione&fields[]=tabella";
    const urlTabelleDownload = urlDirectus + "report/download/";
    let tabelle = [];
    let page = 1;
    let limit = 100;
    let totalItems = 0;

    do {
        const response = await fetch(`${urlTabelle}${filter}${fields}&page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + process.env.REACT_APP_STATIC_TOKEN
            }
        });
        const data = await response.json();
        totalItems = data.data?.length || 0;
        data.data.forEach(item => {
            tabelle.push({ id: item.id, denominazione: item.denominazione, tabella: item.tabella, url: urlTabelleDownload + item.id });
        });
        page++;
    } while (totalItems === limit);

    localStorage.setItem('tabelle', JSON.stringify(tabelle));
}

export async function getStati() {
    await refreshToken();
    const urlStati = urlDirectus + "items/stati";
    //const filter = "?filter[status][_eq]=published";
    const fields = "?fields[]=id&fields[]=nome";
    let stati = [];
    let page = 1;
    let limit = 100;
    let totalItems = 0;

    do {
        const response = await fetch(`${urlStati}${fields}&page=${page}&limit=${limit}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + process.env.REACT_APP_STATIC_TOKEN
            }
        });
        const data = await response.json();
        totalItems = data.data?.length || 0;
        if (data.data) {
            data.data.forEach(item => {
                stati.push({Name: item.nome});
            });
        }
        page++;
    } while (totalItems === limit);

    console.log("lunghezza stati: " + stati.length);

    localStorage.setItem('stati', JSON.stringify(stati));
}

