import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../images/Logo.png';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import WebStorageCache from 'web-storage-cache';
import {client, getUserSalesforceId} from "../../utils/directusClient";
import {getToken} from "../../utils/apiTicketing";

const webStorageCache = new WebStorageCache();

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await client.login(email, password);
            if (response.errors) {
                alert(response.errors[0].message);
                return;
            } else {
                const tokenSf = await getToken();
                if (tokenSf && tokenSf.hasOwnProperty('access_token')) {
                    const token = response;
                    if (token && token.access_token) {
                        const salesforceId = await getUserSalesforceId();
                        if (salesforceId.salesforce_id === null) {
                            alert("Non è possibile autenticarsi senza un ID Salesforce.");
                            return;
                        }
                        //webStorageCache.set('isLoggedIn', true);
                        webStorageCache.set('token', token.access_token);
                        webStorageCache.set('refresh_token', token.refresh_token);
                        webStorageCache.set('salesforceId', salesforceId.salesforce_id);
                        webStorageCache.set('expires', token.expires);
                        webStorageCache.set('expires_at', token.expires_at);
                        localStorage.setItem('isLoggedIn', true);
                        localStorage.setItem('email', email);
                        navigate('/dashboard');
                    } else {
                        alert(token.errors[0].message);
                    }
                } else {
                    console.log(tokenSf);
                }
            }
        } catch (error) {
            alert("Errore durante il login: " + error);
        }
    };

    return (
        <main>
            <Helmet>
                <title>Accedi – Turismo Torino e Provincia</title>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                <meta charSet="utf-8"/>
                <meta name="description" content="Accedi alla tua area riservata." />
                <meta name="robots" content="noindex, nofollow"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#5ac4ed"/>
                <link href="favicon.png" rel="icon" type="image/png" />
                <link href="https://fonts.cdnfonts.com/css/switzer" rel="stylesheet"/>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"/>
                <meta property="og:description" content="Accedi alla tua area riservata." />
                <meta property="og:image:url" content=""/>
                <meta property="og:image:alt" content="Turismo Torino e Provincia"/>
                <meta property="og:locale" content="it" />
                <meta property="og:title" content="Accedi – Turismo Torino e Provincia" />
                <meta property="og:type" content="article" />
                <meta property="og:url" content="" /> {/* Inserire qui l'URL */}
            </Helmet>
            <div className="container-fluid">
                <div className="row justify-content-md-center align-items-center">
                    <div className="col-md-4">
                        <form id="loginForm" onSubmit={handleSubmit}>
                            <a href="/">
                                <img alt="Turismo Torino e Provincia" src={logo} className="d-block mb-3 logo-image" height="100px" />
                            </a>
                            <label htmlFor="validationAnagrafica" className="form-label">E-mail</label>
                            <input id="email" type="email" name="email" className="form-control form-control-lg mb-1" aria-label="E-mail" aria-describedby="Inserisci la tua e-mail" value={email} onChange={e => setEmail(e.target.value)} />
                            <label htmlFor="validationAnagrafica" className="form-label">Password</label>
                            <input id="password" type="password" name="password" className="form-control form-control-lg mb-3" aria-label="Password" aria-describedby="Inserisci la tua password" value={password} onChange={e => setPassword(e.target.value)} />
                            <button className="w-100 btn btn-lg btn-outline-success rounded mb-5" type="submit">Accedi</button>
                            <p className="text-center">&copy;<span id="year">{new Date().getFullYear()}</span> Turismo Torino e Provincia</p>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default LoginPage;