// src/utils/directusClient.js
import {createDirectus, authentication, rest, readMe, staticToken} from '@directus/sdk';
import WebStorageCache from 'web-storage-cache';
const webStorageCache = new WebStorageCache();

const client = createDirectus(process.env.REACT_APP_API_URL).with(authentication('json')).with(rest());
const directus  = createDirectus(process.env.REACT_APP_API_URL).with(staticToken(process.env.REACT_APP_STATIC_TOKEN)).with(rest());

async function getUserSalesforceId() {
    return await client.request(
        readMe({
            fields: ['salesforce_id'],
        })
    );
}

async function refreshToken() {
    return !!(localStorage.getItem('isLoggedIn') && localStorage.getItem('email'));
}


/*
async function refreshToken() {
    try {
        if (webStorageCache.get('isLoggedIn')) {
            const timestamp = webStorageCache.get('expires_at');
            const now = new Date().getTime();
            if (timestamp < now) {
                console.log('Refreshing token');
                const result = await client.refresh();
                if(result.access_token){
                    webStorageCache.set('token', result.access_token);
                    webStorageCache.set('refresh_token', result.refresh_token);
                    webStorageCache.set('expires_at', new Date().getTime() + 3600000);
                    webStorageCache.set('isLoggedIn', true);
                } else {
                    webStorageCache.delete('isLoggedIn');
                    webStorageCache.delete('token');
                    webStorageCache.delete('refresh_token');
                    webStorageCache.delete('expires_at');
                    webStorageCache.delete('salesforceId');
                    alert('Sessione scaduta, effettuare nuovamente il login');
                    return null;
                }
                return true;
            }
        } else {
            return null;
        }
    } catch (error) {
        webStorageCache.delete('isLoggedIn');
        webStorageCache.delete('token');
        webStorageCache.delete('refresh_token');
        webStorageCache.delete('salesforceId');
        return null;
    }

}
 */
export  {client, directus, getUserSalesforceId, refreshToken};