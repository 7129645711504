// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: 'Switzer', sans-serif;
    font-size: 1rem;
}

.text-success {
    color: #5ac4ed!important;
}

.nav-link.active, .btn-success {
    background-color: #5ac4ed!important;
}

.btn-outline-success {
    border-color: #5ac4ed!important;
    color: #5ac4ed!important;
}

.btn-outline-success:hover {
    background-color: #5ac4ed!important;
    color: #fff!important;
}

.bg-success {
    background-color: #5ac4ed!important;
}

nav.navbar, .mob {
    display: none;
}

@media (max-width: 799px) {
    nav.col-3, .no-mob {
        display: none;
    }

    nav.navbar, .mob {
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Tickets/TicketsPage.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,+BAA+B;IAC/B,wBAAwB;AAC5B;;AAEA;IACI,mCAAmC;IACnC,qBAAqB;AACzB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":["* {\r\n    font-family: 'Switzer', sans-serif;\r\n    font-size: 1rem;\r\n}\r\n\r\n.text-success {\r\n    color: #5ac4ed!important;\r\n}\r\n\r\n.nav-link.active, .btn-success {\r\n    background-color: #5ac4ed!important;\r\n}\r\n\r\n.btn-outline-success {\r\n    border-color: #5ac4ed!important;\r\n    color: #5ac4ed!important;\r\n}\r\n\r\n.btn-outline-success:hover {\r\n    background-color: #5ac4ed!important;\r\n    color: #fff!important;\r\n}\r\n\r\n.bg-success {\r\n    background-color: #5ac4ed!important;\r\n}\r\n\r\nnav.navbar, .mob {\r\n    display: none;\r\n}\r\n\r\n@media (max-width: 799px) {\r\n    nav.col-3, .no-mob {\r\n        display: none;\r\n    }\r\n\r\n    nav.navbar, .mob {\r\n        display: block;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
