import React, { useEffect, useState, useRef, useContext } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from "../../components/Sidebar";
import './DashboardPage.css';
import WebStorageCache from 'web-storage-cache';
import { useNavigate } from 'react-router-dom';
import {client, refreshToken} from "../../utils/directusClient";
import { getTickets, getToken, getTemplates } from "../../utils/apiTicketing";
import { LoadingContext} from "../../utils/LoadingContext";
import { Link } from 'react-router-dom';
import {getCategorie, getSedi, getTabelle, getStati} from "../../utils/api-db";

const webStorageCache = new WebStorageCache();

const DashboardPage = () => {
    const [date, setDate] = useState('');
    const [dayName, setDayName] = useState('');
    const dateElement = useRef(null);
    const dayNameElement = useRef(null);
    const navigate = useNavigate();
    const { setIsLoading } = useContext(LoadingContext);
    const { isLoading } = useContext(LoadingContext);


    const effectRan = useRef(false);

    useEffect(() => {
        if (!effectRan.current) {
            setIsLoading(true);
            // Set current date
            const now = new Date();
            const day = now.getDate();
            const month = now.getMonth() + 1;
            const year = now.getFullYear();
            const days = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
            const dayName = days[now.getDay()];
            const formattedDate = day + '.' + month + '.' + year;

            setDate(formattedDate);
            setDayName(dayName);

            if (dateElement.current) {
                dateElement.current.textContent = formattedDate;
            }
            if (dayNameElement.current) {
                dayNameElement.current.textContent = dayName;
            }

            const initializeDashboard = async () => {
                await refreshToken();
                if (!localStorage.getItem('isLoggedIn') || !webStorageCache.get('salesforceId')) {
                    navigate('/login');
                    return;
                }
                sessionStorage.removeItem('selectedContactId');
                await getTickets(true);
                await getTickets(false);
                await getTemplates();
                await getSedi();
                await getCategorie();
                await getTabelle();
                await getStati();
            }

            const fetchDataFromLocalStorage = async () => {
                await initializeDashboard();
                setIsLoading(false);
                if (document.getElementById('value_ticket')) {
                    document.getElementById('value_ticket').textContent = localStorage.getItem('nTickets') || '0';
                }
                if (document.getElementById('value_ticket2')) {
                    document.getElementById('value_ticket2').textContent = localStorage.getItem('nTickets') || '0';
                }
                if (document.getElementById('value_bozze')) {
                    document.getElementById('value_bozze').textContent = localStorage.getItem('nTicketsBozze') || '0';
                }
                if (document.getElementById('value_bozze_2')) {
                    document.getElementById('value_bozze_2').textContent = localStorage.getItem('nTicketsBozze') || '0';
                }
            }

            fetchDataFromLocalStorage();
        }
        effectRan.current = true;
    }, [navigate, setIsLoading]);

    const sidebarWidth = window.innerWidth <= 800 ? '16.6667%' : '25%';

    return(
        <main>
            <Helmet>
                <title>Dashboard - Turismo Torino e Provincia</title>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                <meta charSet="utf-8"/>
                <meta name="robots" content="noindex, nofollow"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#5ac4ed"/>
                <link href="favicon.png" rel="icon" type="image/png"/>
                <link href="https://fonts.cdnfonts.com/css/switzer" rel="stylesheet"/>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet"
                      integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                      crossOrigin="anonymous"/>
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
                        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
                        crossOrigin="anonymous"></script>
                <link rel="stylesheet"
                      href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"/>
            </Helmet>
            <div className="container-fluid no-padding-left">
                <div className="row">
                    <Sidebar className="no-margin-left"/>
                    <div className="col-md-9 p-4 responsive-margin"
                         style={{marginLeft: sidebarWidth, width: `calc(100% - ${sidebarWidth})`}}>
                        <div className="card mb-4 btn-success no-mob">
                            <div className="card-body" style={{maxHeight: '70px'}}>
                                <div className="row">
                                    <div className="col-md-4 fw-bold">
                                        <p className="fs-4 mb-0"><i className="bi bi-speedometer2"></i> Dashboard</p>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <p className="mb-0"><span className="fs-4">Ciao</span>, cosa vuoi fare oggi?</p>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <p id="date" className="mb-0"><span id="dayName" className="fs-4"
                                                                            ref={dayNameElement}></span> <span
                                            id="dateValue" ref={dateElement}></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <div className="card btn-success mb-3 text-center text-white"
                                     style={{borderStyle: 'dotted'}}>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold">Ticket</h5>
                                        <h6 className="card-subtitle mb-3"><i className="bi bi-ticket-fill"></i></h6>
                                        <p className="card-text fs-1" id="value_ticket2">0</p>
                                        <a href="/tickets/Tickets"
                                           className={`card-link text-white ${isLoading ? 'disabled' : ''}`}
                                           onClick={(e) => {
                                               if (isLoading) e.preventDefault();
                                           }}>
                                            Vedi <i className="bi bi-file-earmark-spreadsheet-fill"></i>
                                        </a>
                                        <a href="/crea-ticket"
                                           className={`card-link text-white ${isLoading ? 'disabled' : ''}`}
                                           onClick={(e) => {
                                               if (isLoading) e.preventDefault();
                                           }}>
                                            Crea<i className="bi bi-plus"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="card btn-success text-center text-white"
                                     style={{borderStyle: 'dotted'}}>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold">Bozze</h5>
                                        <h6 className="card-subtitle mb-3"><i className="bi bi-person-fill"></i></h6>
                                        <p className="card-text fs-1" id="value_bozze">0</p>
                                        {isLoading ? (
                                            <span className={`card-link text-white disabled`}>
                                                Vedi <i className="bi bi-file-earmark-spreadsheet-fill"></i>
                                            </span>
                                        ) : (
                                            <Link to={'/tickets/Bozze'} state={{from: "Bozze"}}
                                                  className={`card-link text-white`}>
                                                Vedi <i className="bi bi-file-earmark-spreadsheet-fill"></i>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            { /*<div className="col-md-4">
                                <div className="card" style={{borderStyle: 'dotted', borderColor: '#006681'}}>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold">Help!</h5>
                                        <h6 className="card-subtitle mb-0 text-muted fw-bold">Hai bisogno di assistenza?</h6>
                                        <p className="card-text mb-1">Se hai bisogno di assistenza, non esitare a contattarci. Siamo qui per aiutarti!</p>
                                        <i className="bi bi-telephone-fill"></i> <a href="tel:+393715161109" className="card-link link-dark">+39 371 516 1109</a>
                                        <p className="mt-3 mb-0 text-muted">Questa <em>web application</em> è stata creata da <a className="text-muted" href="https://www.pensativa.it/" rel="sponsored" target="_blank">Pensativa</a>.</p>
                                    </div>
                                </div>
                            </div>*/}

                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default DashboardPage;