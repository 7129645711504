import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Sidebar from "../../components/Sidebar";
import QuestionBlock from '../../components/QuestionBlock';
import applyAutocomplete from "../../utils/getComuni";
import ReactSelect from 'react-select';
import './CreateTicketPage.css';
import WebStorageCache from 'web-storage-cache';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import $ from 'jquery';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import {handleInputRisorse} from '../../utils/HandleInputRisorse';
import {initializeForm, post, checkCampiCompilati,checkRichieste, searchContact, createModal} from '../../utils/fomsInit';
import {professioni, contacts, contactsAssegna, firme} from "../../utils/consts";
import TabelleDropdown from "../../components/Allegati";

const webStorageCache = new WebStorageCache();

const CreateTicketPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    //mostra/nascondi ticket nominativo
    const showTicket = useRef(null);
    const boxTicket = useRef(null);

    //mostra/nascondi email
    const showEmail = useRef(null);
    const boxEmail = useRef(null);

    //editor email
    const [editorContent, setEditorContent] = useState('');

    //validazione form
    const [isValid, setIsValid] = useState(false);

    //inserimento data corrente
    const [dateInput, setDateInput] = useState('');

    //esegue l'effect solo la prima volta
    const effectRan = useRef(false);

    //select categoria
    const [selectedOption, setSelectedOption] = useState(null);

    //richieste multiple
    const [questionBlocks, setQuestionBlocks] = useState([0]);
    const newRequestRef = useRef(null);
    const deleteRequestRef = useRef(null);
    const [blockNumber, setBlockNumber] = useState(0);
    const quillRef = useRef();

    const [selectedIds, setSelectedIds] = useState(JSON.parse(sessionStorage.getItem('selectedIds')) || {});

    const categorieOptions = JSON.parse(localStorage.getItem('categorie')) || [];

    const [selectedSede, setSelectedSede] = useState("");
    const sedi = JSON.parse(localStorage.getItem('sedi')) || [];
    const sediPermanenti = sedi.filter(sede => !sede.temporanea);
    const sediTemporanee = sedi.filter(sede => sede.temporanea);
    const sediOptions = [
        {
            label: 'Sedi permanenti',
            options: sediPermanenti.map(sede => ({ value: sede.salesforceId, label: sede.nome }))
        }
    ];
    if (sediTemporanee.length > 0) {
        sediOptions.push({
            label: 'Sedi temporanee',
            options: sediTemporanee.map(sede => ({ value: sede.salesforceId, label: sede.nome }))
        });
    }

    useEffect(() => {
        const storedSede = localStorage.getItem('selectedSede');
        if (storedSede) {
            setSelectedSede(storedSede);
        }
    }, []);

    const handleSedeChange = (e) => {
        const sede = e.target.value;
        setSelectedSede(sede);
        localStorage.setItem('selectedSede', sede);
    };

    let activityEmails = contacts.reduce((acc, contact) => {
        if (!acc.find(item => item.email === contact.activityEmail)) {
            acc.push({name: contact.activityEmail, email: contact.activityEmail});
        }
        return acc;
    }, []);

    let personalEmails = contacts.reduce((acc, contact) => {
        if (contact.personalEmail && !acc.find(item => item.email === contact.personalEmail)) {
            acc.push({name: `${contact.firstName} ${contact.lastName}`, email: contact.personalEmail});
        }
        return acc;
    }, []);

    let activityEmailsAssegna = contactsAssegna.reduce((acc, contact) => {
        if (!acc.find(item => item.email === contact.activityEmail)) {
            acc.push({name: contact.activityEmail, email: contact.activityEmail});
        }
        return acc;
    }, []);

    activityEmails.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    personalEmails.sort((a, b) => (a.name || "").localeCompare(b.name || ""));
    activityEmailsAssegna.sort((a, b) => (a.name || "").localeCompare(b.name || ""));

    let uniqueEmails = [...activityEmails, ...personalEmails];

    let localStorageEmail = localStorage.getItem('email');
    let mitteEmails = [];
    if (localStorageEmail) {
        mitteEmails.push({name: localStorageEmail, email: localStorageEmail})
    }
    mitteEmails = [...mitteEmails, ...activityEmails];

    //firme
    const [selectedFirme, setSelectedFirme] = useState('');
    const emailPrefix = localStorageEmail ? localStorageEmail.split('@')[0] : '';
    let filteredFirme = [];
    filteredFirme = firme.filter(firma => firma.nome.toLowerCase().includes(emailPrefix.toLowerCase()) || !firma.nome.includes('-'));
    const firmeOptions = filteredFirme.map(firma => ({ value: firma.nome, label: firma.nome }));

    //code Assegna e notificha
    const [codaAssegnazione, setCodaAssegnazione] = useState(null);
    const [notificaTicket, setNotificaTicket] = useState(null);

    //const firmeOptions = firme.map(firma => ({ value: firma.nome, label: firma.nome }));
    console.log(firmeOptions);

    const handleFirmeChange = (event) => {
        console.log(event.target.value);
        setSelectedFirme(event.target.value);
    };

    // StatoTicket:
    // 0: Ticket anonimo, si chiude automaticamente;
    // 1: Ticket non  anonimo e chiuso;
    // 2: Ticket non anonimo ma deve essere rielaborato;
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if (document.activeElement.id === 'typeDropdownButton' || document.activeElement.id === ''|| document.activeElement.id === 'newRequest') {
        } else {
            let buttonId = document.activeElement.id;
            setIsValid(true);
            const quill = quillRef.current.getEditor();
            const cover = sessionStorage.getItem('cover');
            let statoTicket = 0;
            let invioEmail = false;
            let token = '';
            let bozza = false;
            if (buttonId === 'sendEmail' || buttonId === 'closeNameTicket' || buttonId === 'createAnonTicket' || buttonId === 'saveTicket' || buttonId === 'createBozzaAnonTicket') {
                let success = false;
                switch (buttonId) {
                    case 'createBozzaAnonTicket':
                        if (checkCampiCompilati("createBozzaAnonTicket", quill, selectedOption,selectedSede, selectedFirme)) {
                            console.log("sono in createBozzaAnonTicket\n");
                            bozza = true;
                            success = await post('crea-ticket', statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket);
                            bozza = false;
                            console.log("success: " + success);
                            if (success)
                                navigate('/dashboard');
                        }
                        break;
                    case 'sendEmail':
                        if (checkCampiCompilati("sendEmail", quill, selectedOption,selectedSede, selectedFirme)) {
                            invioEmail = true;
                            statoTicket = checkRichieste();
                            console.log("sono in sendEmail\n");
                            console.log("statoTicket: " + statoTicket);
                            success = await post('crea-ticket', statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket);
                            invioEmail = false;
                            statoTicket = 0;
                            if (success)
                                navigate('/dashboard');
                        }
                        break;
                    case 'closeNameTicket':
                        if (checkCampiCompilati("closeNameTicket", quill, selectedOption,selectedSede, selectedFirme)) {
                            statoTicket = checkRichieste();
                            console.log("sono in closeNameTicket\n");
                            console.log("statoTicket: " + statoTicket);
                            success = await post('crea-ticket', statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket);
                            statoTicket = 0;
                            if (success)
                                navigate('/dashboard');
                        }
                        break;
                    case 'createAnonTicket':
                        if (checkCampiCompilati("createAnonTicket", quill, selectedOption,selectedSede, selectedFirme)) {
                            console.log("sono in createAnonTicket\n");
                            console.log("statoTicket: " + statoTicket);
                            success = await post('crea-ticket', statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket);
                            if (success)
                                navigate('/dashboard');
                        }
                        break;
                    case 'saveTicket':
                        if (checkCampiCompilati("saveTicket", quill, selectedOption,selectedSede, selectedFirme)) {
                            bozza = true;
                            statoTicket = checkRichieste();
                            if (statoTicket ===1) { statoTicket = 2; }
                            success = await post('crea-ticket', statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket);
                            bozza = false;
                            if (success)
                                navigate('/dashboard');
                        }
                        break;
                    default:
                        break;
                }

            }

        }
    };

    const handleClickNewRequest = (e) => {
        setQuestionBlocks(prevBlocks => [...prevBlocks, prevBlocks.length]);
        setBlockNumber(prevBlockNumber => prevBlockNumber + 1);
        //tengo traccia del numero di blocchi di domande
    };

    const handleClickDeleteRequest = (e) => {
        e.preventDefault();
        if (questionBlocks.length > 1) {
            setQuestionBlocks(prevBlocks => prevBlocks.slice(0, -1));
            setBlockNumber(prevBlockNumber => prevBlockNumber - 1);
        }

        let nRichieste = parseInt(sessionStorage.getItem('questionBlocks'));
        let selectedIds = JSON.parse(sessionStorage.getItem('selectedIds')) || {};
        let entries = Object.entries(selectedIds);
        if (entries.length === nRichieste ) {entries.pop();}
        selectedIds = Object.fromEntries(entries);
        console.log(selectedIds);
        sessionStorage.setItem('selectedIds', JSON.stringify(selectedIds));
    };

    useEffect(() => {
        const storedSedi = localStorage.getItem('sedi');
        const storedCategorie = localStorage.getItem('categorie');
        if (!storedSedi || !storedCategorie) {
            navigate('/dashboard');
        }
    }, [navigate]);

    useEffect(() => {
        sessionStorage.setItem('questionBlocks', questionBlocks.length.toString());
    }, [questionBlocks]);

    useEffect(() => {
        if (!effectRan.current) {
            //inserimento data corrente in validationData
            const today = new Date().toISOString().split('T')[0];
            setDateInput(today);

            //mostra/nascondi ticket nominativo
            if (showTicket.current) {
                showTicket.current.addEventListener('change', function () {
                    if (this.checked) {
                        boxTicket.current.style.display = 'block';
                        document.getElementById("createAnonTicket").disabled = true;
                        document.getElementById("createBozzaAnonTicket").disabled = true;
                        if (showEmail.current.checked) {
                            boxEmail.current.style.display = 'block';
                        }
                    } else {
                        boxTicket.current.style.display = 'none';
                        document.getElementById("createAnonTicket").disabled = false;
                        document.getElementById("createBozzaAnonTicket").disabled = false;
                        boxEmail.current.style.display = 'none';
                    }
                });
            }

            //mostra/nascondi email
            if (showEmail.current) {
                showEmail.current.addEventListener('change', function () {
                    if (this.checked) {
                        boxEmail.current.style.display = 'block';
                    } else {
                        boxEmail.current.style.display = 'none';
                    }
                });
            }

            //richieste multiple
            let newRequestRefCurrent = newRequestRef.current;
            if (newRequestRefCurrent) {
                newRequestRefCurrent.addEventListener('click', handleClickNewRequest);
            }
            let deleteRequestRefCurrent = deleteRequestRef.current;
            if (deleteRequestRefCurrent) {
                deleteRequestRefCurrent.addEventListener('click', handleClickDeleteRequest);
            }
            document.body.addEventListener('input', (e) => handleInputRisorse(e, selectedIds, setSelectedIds, process.env.REACT_APP_API_URL_SEARCH));

            applyAutocomplete();
            const fetchData = async () => {
                await initializeForm('crea-ticket', quillRef);
            }
            fetchData();
        }

        return () => {
            effectRan.current = true;
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            $(".dropdown-menu li button").click(function () {
                $("#typeDropdownButton").text($(this).text());
            });
        }, 0);

        return () => clearTimeout(timer); // Pulizia alla dismontaggio
    }, []);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const dropdownButton = document.querySelector("#typeDropdownButton");
        const dropdownItems = document.querySelectorAll(".dropdown-menu li");

        if (dropdownButton) {
            dropdownButton.addEventListener('click', toggleDropdown);
        }

        // Aggiungi un ascoltatore di eventi 'click' a ciascun elemento del menu a discesa
        dropdownItems.forEach(item => {
            item.addEventListener('click', () => setDropdownOpen(false));
        });

        return () => {
            if (dropdownButton) {
                dropdownButton.removeEventListener('click', toggleDropdown);
            }

            // Rimuovi l'ascoltatore di eventi 'click' da ciascun elemento del menu a discesa
            dropdownItems.forEach(item => {
                item.removeEventListener('click', () => setDropdownOpen(false));
            });
        };
    }, []);

    useEffect(() => {
        const dropdownMenu = document.querySelector(".dropdown-menu");
        if (dropdownMenu) {
            if (dropdownOpen) {
                dropdownMenu.classList.add('show');
            } else {
                dropdownMenu.classList.remove('show');
            }
        }
    }, [dropdownOpen]);

    //Bottone ricerca mail
    useEffect(() => {
        const button = document.getElementById('button-addon2');

        const handleClick = async () => {
            const email = document.getElementById('validationEmail').value || '';
            const nome = document.getElementById('validationNome').value || '';
            const cognome = document.getElementById('validationCognome').value || '';
            const comune = document.getElementById('validationComune').value || '';
            if (email || nome || cognome || comune) {
                const contacts = await searchContact(email, nome, cognome, comune);
                console.log(JSON.stringify(contacts));
                createModal(contacts);
            }
        };

        if (button) {
            button.addEventListener('click', handleClick);
        }

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            if (button) {
                button.removeEventListener('click', handleClick);
            }
        };
    }, []);

    return (
        <main>
            <Helmet>
                <title>Crea ticket - Turismo Torino e Provincia</title>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                <meta charSet="utf-8"/>
                <meta name="robots" content="noindex, nofollow"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#8b0101"/>
                <link href="favicon.png" rel="icon" type="image/png"/>
                <link href="https://fonts.cdnfonts.com/css/switzer" rel="stylesheet"/>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css" rel="stylesheet"
                      integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9"
                      crossOrigin="anonymous"/>
                <link rel="stylesheet"
                      href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"/>
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.14.0-beta2/css/bootstrap-select.min.css"/>
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <Sidebar/>
                    <div className="col-md-9 p-4" style={{ marginLeft: "25%" }}>
                        <div className="card mb-4 btn-success text-center mob">
                            <div className="card-body" style={{maxHeight: "70px"}}>
                                <div className="row" style={{maxHeight: "70px"}}>
                                    <div className="col fw-bold" style={{maxHeight: "70px"}}>
                                        <p className="fs-4 mb-0"><i className="bi bi-plus"></i> Crea ticket</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form className={`needs-validation ${isValid ? 'was-validated' : ''}`} onSubmit={handleFormSubmit} noValidate>
                            <div className="col-12">
                                <p className="mb-0">* obbligatorio</p>
                                <p className="mb-0 fs-5 fw-bold">Step 1: Dati Essenziali Ticket Anonimo</p>
                            </div>
                            <div className="row" style={{maxHeight: "100px"}}>
                                <div className="col-md-3">
                                    <label htmlFor="validationSede" className="form-label custom-select">Sede*</label>
                                    <select className="form-select" aria-label="Sede*" required name=""
                                            id="validationSede" value={selectedSede}
                                            onChange={handleSedeChange}>
                                        <option style={{display: "none"}} value="">Seleziona la sede
                                        </option>
                                        {sediOptions.map((optgroup, i) => (
                                            <optgroup key={i} label={optgroup.label}>
                                                {optgroup.options.map((option, j) => (
                                                    <option key={j} value={option.value}>{option.label}</option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </select>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Seleziona una sede
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationCanale" className="form-label">Modalità*</label>
                                    <select className="form-select" aria-label="Canale*" required name=""
                                            id="validationCanale">
                                        <option value="Vis-A-Vis">Vis-A-Vis</option>
                                        <option value="E-Mail">E-Mail</option>
                                        <option value="Telefono">Telefono</option>
                                        <option value="Fax">Fax</option>
                                        <option value="Lettera">Lettera</option>
                                        <option value="Coupon/Cartolina">Coupon/Cartolina</option>
                                        <option value="Biglietto Da Visita">Biglietto Da Visita</option>
                                        <option value="Web">Web</option>
                                    </select>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Seleziona una modalità
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationMF" className="form-label">Sesso</label>
                                    <select className="form-select" aria-label="Sesso*" name="" id="validationSesso">
                                        <option style={{display: "none"}} value="">Seleziona il sesso
                                        </option>
                                        <option value="Maschio">Maschio</option>
                                        <option value="Femmina">Femmina</option>
                                        <option value="ND">ND</option>
                                    </select>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Seleziona una lingua
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationLingua" className="form-label">Lingua*</label>
                                    <select className="form-select" aria-label="Provincia*" name=""
                                            id="validationLingua">
                                        <option style={{display: "none"}} value="">Seleziona la lingua
                                        </option>
                                        <option value="Italiano">Italiano</option>
                                        <option value="Inglese">Inglese</option>
                                        <option value="Francese">Francese</option>
                                        <option value="Tedesco">Tedesco</option>
                                        <option value="Spagnolo">Spagnolo</option>
                                        <option value="Russo">Russo</option>
                                        <option value="Arabo">Arabo</option>
                                        <option value="Cinese">Cinese</option>
                                        <option value="Giapponese">Giapponese</option>
                                        <option value="Turco">Turco</option>
                                        <option value="Portoghese">Portoghese</option>
                                    </select>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Seleziona una lingua
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{maxHeight: "100px"}}>
                                <div className="col-md-4">
                                    <label htmlFor="validationNazione" className="form-label">Nazione*</label>
                                    <input type="text" className="form-control" name="" id="validationNazione"/>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Inserisci una nazione
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationRegione" className="form-label">Regione</label>
                                    <input type="text" className="form-control" name="" id="validationRegione"/>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Inserisci una regione
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationComune" className="form-label">Comune</label>
                                    <input type="text" className="form-control" name="" id="validationComune"/>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Inserisci un comune
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{maxHeight: "100px"}}>
                                <div className="col-md-4">
                                    <label htmlFor="validationCategoria" className="form-label">Categoria*</label>
                                    <ReactSelect
                                        isMulti
                                        options={categorieOptions}
                                        className="selectpicker"
                                        classNamePrefix="select"
                                        id="validationCategoria"
                                        name=""
                                        aria-label="Categoria*"
                                        title="Seleziona categoria..."
                                        data-live-search="true"
                                        value={selectedOption}
                                        onChange={setSelectedOption}
                                    />
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Seleziona una categoria
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationData" className="form-label">Data*</label>
                                    <input type="date" className="form-control" name="" id="validationData"
                                           value={dateInput}
                                           onChange={e => setDateInput(e.target.value)}/>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Seleziona una data
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="validationPersone" className="form-label">Persone*</label>
                                    <input type="number" className="form-control" name="" id="validationPersone" min="1"
                                           placeholder="1"/>
                                    <div className="valid-feedback">
                                        Ottimo lavoro!
                                    </div>
                                    <div className="invalid-feedback">
                                        Inserisci il numero di persone
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{maxHeight: "120px"}}>
                                <div className="col-md-7">
                                </div>
                                <div className="col-2" >
                                    <button className="btn btn-outline-success"  id="createAnonTicket" style={{width: "100%"}}>
                                        Crea Ticket Anonimo
                                    </button>
                                </div>
                                <div className="col-3" >
                                    <button className="btn btn-outline-success"
                                            id="createBozzaAnonTicket">Salva
                                        bozza Ticket Anonimo
                                    </button>
                                </div>
                                <div className="col-12">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch"
                                               id="showTicketNominativo" ref={showTicket}/>
                                        <label className="form-check-label" htmlFor="showTicketNominativo">Aggiungi Dati
                                            Ticket
                                            Nominativo</label>
                                    </div>
                                </div>
                            </div>
                            <div id="boxTicketNominativo" style={{display: "none"}} ref={boxTicket}>
                                <div className="row" style={{maxHeight: "30px"}}>
                                    <div className="col-12">
                                        <p className="fs-5 fw-bold">Step 2: Ticket Nominativo</p>
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: "100px"}}>
                                    <div className="row mb-3" style={{maxHeight: "100px"}}>
                                        <div className="col-md-4">
                                            <label htmlFor="validationEmail" className="form-label">E-mail*</label>
                                            <div className="input-group">
                                                <input type="email" className="form-control" name=""
                                                       id="validationEmail"/>
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon2"><i
                                                    className="bi bi-search"></i></button>
                                            </div>
                                            <div className="valid-feedback">
                                                Ottimo lavoro!
                                            </div>
                                            <div className="invalid-feedback">
                                                Inserisci un indirizzo e-mail valido
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationNome" className="form-label">Nome</label>
                                            <input type="text" className="form-control" name="" id="validationNome"/>
                                            <div className="valid-feedback">
                                                Ottimo lavoro!
                                            </div>
                                            <div className="invalid-feedback">
                                                Inserisci un nome
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCognome" className="form-label">Cognome</label>
                                            <input type="text" className="form-control" name="" id="validationCognome"/>
                                            <div className="valid-feedback">
                                                Ottimo lavoro!
                                            </div>
                                            <div className="invalid-feedback">
                                                Inserisci un cognome
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: "100px"}}>
                                    <div className="row mb-3" style={{maxHeight: "100px"}}>
                                        <div className="col-md-6" style={{maxHeight: "100px"}}>
                                            <label htmlFor="validationRS" className="form-label">Ragione sociale/Nome
                                                Istituto</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" name="" id="validationRS"/>
                                                <button id="typeDropdownButton"
                                                        className="btn btn-outline-secondary dropdown-toggle"
                                                        type="button"
                                                        data-bs-toggle="dropdown" aria-expanded="false"
                                                        onClick={(event) => {
                                                        }}
                                                        style={{whiteSpace: 'nowrap'}}>
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end">
                                                    <li>
                                                        <button className="dropdown-item">Scuola</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item">Trade</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item">Mice</button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item">Press</button>
                                                    </li>
                                                </ul>
                                                <div className="valid-feedback">
                                                    Ottimo lavoro!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                        <label htmlFor="validationComuneRS" className="form-label">Comune</label>
                                            <input type="text" className="form-control" name="" id="validationComuneRS"
                                                   placeholder=""/>
                                            <div className="valid-feedback">
                                                Ottimo lavoro!
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label htmlFor="validationNazioneRS" className="form-label">Nazione</label>
                                            <input type="text" className="form-control" name="" id="validationNazioneRS"
                                                   placeholder=""/>
                                            <div className="valid-feedback">
                                                Ottimo lavoro!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: "90px"}}>
                                    <div className="col-md-2">
                                        <label htmlFor="validationTitolo" className="form-label">Titolo</label>
                                        <input type="text" className="form-control" name=""
                                               id="validationTitolo"/>
                                        <div className="valid-feedback">
                                            Ottimo lavoro!
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationRuolo" className="form-label">Ruolo</label>
                                        <select className="form-control" name="" id="validationRuolo">
                                            <option value="">Seleziona un ruolo</option>
                                            <option value="Agente di viaggi">Agente di viaggi</option>
                                            <option value="Ambasciatore">Ambasciatore</option>
                                            <option value="Amministratore">Amministratore</option>
                                            <option value="Assessore">Assessore</option>
                                            <option value="Assistente">Assistente</option>
                                            <option value="Booking">Booking</option>
                                            <option value="Cancelliere">Cancelliere</option>
                                            <option value="Capitano">Capitano</option>
                                            <option value="Capo">Capo</option>
                                            <option value="Capogruppo">Capogruppo</option>
                                            <option value="CEO">CEO</option>
                                            <option value="Chairman">Chairman</option>
                                            <option value="Comandante">Comandante</option>
                                            <option value="Commissario">Commissario</option>
                                            <option value="Comunicazione">Comunicazione</option>
                                            <option value="Congress Manager">Congress Manager</option>
                                            <option value="Consigliere">Consigliere</option>
                                            <option value="Console">Console</option>
                                            <option value="Consulente Viaggi">Consulente Viaggi</option>
                                            <option value="Delegato">Delegato</option>
                                            <option value="Direttore">Direttore</option>
                                            <option value="Direzione">Direzione</option>
                                            <option value="Dirigente">Dirigente</option>
                                            <option value="Docente">Docente</option>
                                            <option value="Freelance">Freelance</option>
                                            <option value="Funzionario">Funzionario</option>
                                            <option value="Gestore">Gestore</option>
                                            <option value="Governatore">Governatore</option>
                                            <option value="Grafico">Grafico</option>
                                            <option value="Incentive & Congress Manager">Incentive & Congress Manager</option>
                                            <option value="Legale Rappresentante">Legale Rappresentante</option>
                                            <option value="Marketing Manager">Marketing Manager</option>
                                            <option value="Medico">Medico</option>
                                            <option value="Membro">Membro</option>
                                            <option value="Preside">Preside</option>
                                            <option value="Presidente">Presidente</option>
                                            <option value="Product Manager">Product Manager</option>
                                            <option value="Produttore">Produttore</option>
                                            <option value="Professore">Professore</option>
                                            <option value="Project Leader">Project Leader</option>
                                            <option value="Project Manager">Project Manager</option>
                                            <option value="Questore">Questore</option>
                                            <option value="Rappresentante">Rappresentante</option>
                                            <option value="Referente">Referente</option>
                                            <option value="Referente FIT">Referente FIT</option>
                                            <option value="Referente gruppi">Referente gruppi</option>
                                            <option value="Responsabile - Manager">Responsabile - Manager</option>
                                            <option value="Responsabile Centro Congressi">Responsabile Centro Congressi</option>
                                            <option value="Responsabile Eventi">Responsabile Eventi</option>
                                            <option value="Rettore">Rettore</option>
                                            <option value="Revisore dei Conti">Revisore dei Conti</option>
                                            <option value="Ricercatore">Ricercatore</option>
                                            <option value="Segretario Generale">Segretario Generale</option>
                                            <option value="Segretario/Segretariato">Segretario/Segretariato</option>
                                            <option value="Segreteria">Segreteria</option>
                                            <option value="Sindaco">Sindaco</option>
                                            <option value="Socio - Partner">Socio - Partner</option>
                                            <option value="Soprintendente">Soprintendente</option>
                                            <option value="Staff">Staff</option>
                                            <option value="Stagista">Stagista</option>
                                            <option value="Tesoriere">Tesoriere</option>
                                            <option value="Titolare">Titolare</option>
                                            <option value="Vice">Vice</option>
                                            <option value="Vice Presidente">Vice Presidente</option>
                                        </select>
                                        <div className="valid-feedback">
                                            Ottimo lavoro!
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationProfessione"
                                               className="form-label">Professione</label>
                                        <select className="form-control" name="" id="validationProfessione">
                                            <option value="">Seleziona una professione</option>
                                            {professioni.map((profession, index) => (
                                                <option key={index} value={profession}>{profession}</option>
                                            ))}
                                        </select>
                                        <div className="valid-feedback">
                                            Ottimo lavoro!
                                        </div>
                                        <div className="invalid-feedback">
                                            Inserisci una professione
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: "500px"}}>
                                    <div className="col-md-4" style={{maxHeight: "90px"}}>
                                        <label htmlFor="validationMdT" className="form-label">Mezzo di
                                            trasporto</label>
                                        <select className="form-select" aria-label="Canale*" name=""
                                                id="validationMdT">
                                            <option style={{display: "none"}} value="">Seleziona
                                                il
                                                mezzo di trasporto
                                            </option>
                                            <option value="Auto">Auto</option>
                                            <option value="Treno">Treno</option>
                                            <option value="Aereo">Aereo</option>
                                            <option value="Bus">Bus</option>
                                            <option value="Altro">Altro</option>
                                        </select>
                                        <div className="valid-feedback">
                                            Ottimo lavoro!
                                        </div>
                                        <div className="invalid-feedback">
                                            Selezione un mezzo di trasporto
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{maxHeight: "90px"}}>
                                        <label htmlFor="validationCdV" className="form-label">Compagni di
                                            viaggio</label>
                                        <select className="form-select" aria-label="Canale*" name=""
                                                id="validationCdV">
                                            <option style={{display: "none"}} value="">Seleziona
                                                i
                                                compagni di viaggio
                                            </option>
                                            <option value="Da solo">Da solo</option>
                                            <option value="Con il coniuge/partner">Con il coniuge/partner
                                            </option>
                                            <option value="Con la famiglia">Con la famiglia</option>
                                            <option value="Con amici/colleghi">Con amici/colleghi</option>
                                            <option value="Gruppo organizzato (specificare)">Gruppo organizzato
                                                (specificare)
                                            </option>
                                        </select>
                                        <div className="valid-feedback">
                                            Ottimo lavoro!
                                        </div>
                                        <div className="invalid-feedback">
                                            Seleziona un compagno di viaggio
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{maxHeight: "90px"}}>
                                        <label id="labelSSpecifica" htmlFor="validationGruppoOrganizzato"
                                               className="form-label">Specifica</label>
                                        <input type="text" className="form-control" name=""
                                               id="validationGruppoOrganizzato" placeholder=""/>
                                        <div className="valid-feedback">
                                            Ottimo lavoro!
                                        </div>
                                    </div>
                                    <div className="questionsBlock border border-black mt-3 p-3 pt-0">
                                        {questionBlocks.map(block => (
                                            // renderizza un blocco di domande per ogni elemento nell'array questionBlocks
                                            <QuestionBlock key={block} blockNumber={block}/>
                                        ))}
                                    </div>
                                    <div className="col-12 mt-3">
                                        <button id="newRequest" className="btn btn-outline-secondary"
                                                onClick={handleClickNewRequest}> Nuova richiesta
                                        </button>
                                        {questionBlocks.length > 1 &&
                                            <button id="deleteRequest" className="btn btn-outline-danger ms-2"
                                                    onClick={handleClickDeleteRequest}>Elimina richiesta</button>
                                        }
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="card" style={{ borderStyle: "dotted", borderColor: "#006681" }}>
                                            <div className="card-body">
                                                <p className="mb-0 fs-5 fw-bold">Assegna il Ticket</p>
                                                <label htmlFor="validationOperatore" className="form-label">Operatore</label>
                                                <ReactSelect
                                                    options={activityEmailsAssegna.map(contact => ({ value: contact.email, label: contact.name }))}
                                                    classNamePrefix="select"
                                                    id="validationOperatore"
                                                    name="validationOperatore"
                                                    placeholder="Seleziona un operatore"
                                                    onChange={selectedOptions => setCodaAssegnazione(selectedOptions ? selectedOptions.map(option => option.value).join(',') : '')}
                                                    isSearchable
                                                    isMulti
                                                    styles={{
                                                        menu: provided => ({ ...provided, maxHeight: 150 }),
                                                        menuList: provided => ({ ...provided, maxHeight: 150 })
                                                    }}
                                                />
                                                <div className="valid-feedback">Ottimo lavoro!</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="card" style={{ borderStyle: "dotted", borderColor: "#006681", marginBottom: "30px" }}>
                                            <div className="card-body">
                                                <p className="mb-0 fs-5 fw-bold">Notifica il Ticket</p>
                                                <label htmlFor="validationOperatore2" className="form-label">Operatore</label>
                                                <ReactSelect
                                                    options={uniqueEmails.map(contact => ({ value: contact.email, label: contact.name }))}
                                                    classNamePrefix="select"
                                                    id="validationOperatore2"
                                                    name="validationOperatore2"
                                                    placeholder="Seleziona un operatore"
                                                    onChange={selectedOptions => setNotificaTicket(selectedOptions ? selectedOptions.map(option => option.value).join(',') : '')}
                                                    isSearchable
                                                    isMulti
                                                    styles={{
                                                        menu: provided => ({ ...provided, maxHeight: 150 }),
                                                        menuList: provided => ({ ...provided, maxHeight: 150 })
                                                    }}
                                                />
                                                <div className="valid-feedback">Ottimo lavoro!</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch"
                                                   id="showEmail" ref={showEmail}/>
                                            <label className="form-check-label" htmlFor="showEmail">Vuoi inviare
                                                un'e-mail?</label>
                                        </div>
                                    </div>
                                    <div id="boxEmail" style={{display: "none"}} ref={boxEmail}>
                                        <div className="col-12">
                                            <p className="mb-0 fs-5 fw-bold">Step 3: Invia E-mail</p>
                                        </div>
                                        <div className="row" style={{maxHeight: "600px"}}>
                                            <div className="row" style={{maxHeight: "70px"}}>
                                                <div className="col-md-4" style={{maxHeight: "70px"}}>
                                                    <label htmlFor="validationMittente"
                                                           className="form-label">Mittente*</label>
                                                    <select className="form-select" aria-label="Provincia*" name=""
                                                            id="validationMittente">
                                                        {mitteEmails.map((emailObj, index) => (
                                                            <option key={index}
                                                                    value={emailObj.email}>{emailObj.email}</option>
                                                        ))}
                                                    </select>
                                                    <div className="valid-feedback">
                                                        Ottimo lavoro!
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        Seleziona un mittente
                                                    </div>
                                                </div>
                                                <div className="col-md-4" style={{maxHeight: "70px"}}>
                                                    <label htmlFor="validationCC" className="form-label">CC</label>
                                                    <input type="text" className="form-control" name=""
                                                           id="validationCC"/>
                                                    <div className="valid-feedback">
                                                        Ottimo lavoro!
                                                    </div>
                                                </div>
                                                <div className="col-md-4" style={{maxHeight: "70px"}}>
                                                    <label htmlFor="validationOggetto"
                                                           className="form-label">Oggetto*</label>
                                                    <input type="text" className="form-control" name="name"
                                                           id="validationOggetto"/>
                                                    <div className="valid-feedback">
                                                        Ottimo lavoro!
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        Inserisci l'oggetto
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-3 mb-3">
                                                    <label htmlFor="validationTemplate"
                                                           className="form-label">Template*</label>
                                                    <select className="form-select" aria-label="Provincia*" name=""
                                                            id="validationTemplate">
                                                        <option style={{display: "none"}}
                                                                value="">Seleziona il template
                                                        </option>
                                                    </select>
                                                    <div className="valid-feedback">
                                                        Ottimo lavoro!
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        Seleziona un template
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-3 mb-3">
                                                    <label htmlFor="validationAllegati"
                                                           className="form-label">Allegati </label>
                                                    <TabelleDropdown/>
                                                </div>
                                                <div className="col-md-4 mt-3 mb-3">
                                                    <label htmlFor="validationFirme" className="form-label">Firma*</label>
                                                    <select className="form-select" aria-label="Firme" id="validationFirme" required value={selectedFirme} onChange={handleFirmeChange}>
                                                        <option value="" style={{display: "none"}}>Seleziona una firma</option>
                                                        {firmeOptions.map((option, index) => (
                                                            <option key={index} value={option.value}>{option.label}</option>
                                                        ))}
                                                    </select>
                                                    <div className="valid-feedback">
                                                        Ottimo lavoro!
                                                    </div>
                                                    <div className="invalid-feedback">
                                                        Seleziona una firma
                                                    </div>
                                                </div>
                                                <ReactQuill id="editor" className="mt-0" theme="snow"
                                                            value={editorContent}
                                                            onChange={setEditorContent}
                                                            ref={quillRef}/>
                                                <div className="col-12 mt-5">
                                                    <button className="btn btn-outline-secondary"
                                                            id="sendEmail">Invia
                                                        e-mail e chiudi ticket
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-outline-success" id="saveTicket"
                                                style={{marginBottom: "2px"}}>Salva Ticket in Bozza
                                        </button>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-outline-success"
                                                id="closeNameTicket">Salva/Chiudi Ticket Nominativo
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <script src="https://code.jquery.com/jquery-3.7.0.min.js"
                    integrity="sha256-2Pmvv0kuTBOenSvLm6bvfBSSHrUJ+3A7x6P5Ebd07/g=" crossOrigin="anonymous"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm"
                    crossOrigin="anonymous"></script>
            <script
                src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.14.0-beta2/js/bootstrap-select.min.js"></script>
            <link href="https://code.jquery.com/ui/1.10.2/themes/smoothness/jquery-ui.css" rel="Stylesheet"/>
            <script src="../../lib/jquery-ui-1.13.2/"></script>
            <script src="https://code.jquery.com/ui/1.10.2/jquery-ui.js"></script>
            <script src="https://code.jquery.com/jquery-migrate-3.0.0.min.js"></script>
        </main>
    );
}

export default CreateTicketPage;