import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { postTicket, putTicket, getContact } from './apiTicketing';
import WebStorageCache from 'web-storage-cache';

//funzione ricerca contatti
export async function searchContact(email, nome, cognome, comune) {
    return await getContact(cognome, email, comune, nome);
}
export function createModal(contacts) {
    let modal = document.createElement('div');
    modal.className = 'modal';
    modal.style.display = 'block';

    let modalContent = document.createElement('div');
    modalContent.className = 'modal-content';
    modalContent.style.position = 'fixed';
    modalContent.style.top = '40%';
    modalContent.style.left = '40%';
    modalContent.style.width = '400px';
    modalContent.style.height = '400px';

    let select = document.createElement('select');
    select.id = 'contactSelect';

    select.onchange = function() {
        let selectedContact = contacts.find(contact => contact.Id === this.value);
        if (selectedContact.FirstName)
            document.getElementById('validationNome').value = selectedContact.FirstName || '';
        if (selectedContact.LastName)
            document.getElementById('validationCognome').value = selectedContact.LastName || '';
        if (selectedContact.Email)
            document.getElementById('validationEmail').value = selectedContact.Email || '';
        if (selectedContact.MailingCity)
            document.getElementById('validationComune').value = selectedContact.MailingCity || '';
        if (selectedContact.Id)
            sessionStorage.setItem('selectedContactId', selectedContact.Id);

        modal.style.display = 'none';
    };

    if (contacts.hasOwnProperty('message')) {
        let option = document.createElement('option');
        option.value = '';
        option.textContent = 'Nessun contatto trovato';
        select.appendChild(option);
    } else {
        let defaultOption = document.createElement('option');
        defaultOption.value = '';
        defaultOption.textContent = 'Seleziona un contatto';
        select.appendChild(defaultOption);

        contacts.forEach(contact => {
            let option = document.createElement('option');
            option.value = contact.Id;
            option.textContent = `${contact.FirstName || ''} ${contact.LastName || ''} ${contact.MailingCity || ''}`;
            select.appendChild(option);
        });
    }

    // Append the dropdown menu to the modal content
    modalContent.appendChild(select);

    // Append the modal content to the modal
    modal.appendChild(modalContent);

    // Append the modal to the document body
    document.body.appendChild(modal);

    // Close the modal when clicked outside of the modal content
    window.onclick = function(event) {
        if (event.target === modal) {
            modal.style.display = 'none';
        }
    };

    return modal;
}

export async function initializeForm(location, quillref) {
    const quill = quillref.current.getEditor();
    sessionStorage.removeItem('selectedIds');
    sessionStorage.removeItem('selectedContactId');

    if (location === 'crea-ticket') {document.getElementById('validationPersone').value = 1;}
    if (location === 'modifica-ticket') {sessionStorage.setItem("questionBlocks", "0");}
    //Mostra specifica gruppo organizzato
    var groupSelect = document.getElementById('validationCdV');
    var validationGruppoOrganizzato = document.getElementById('validationGruppoOrganizzato');
    var labelGruppoOrganizzato = document.getElementById('labelSSpecifica');
    validationGruppoOrganizzato.style.display = 'none';
    labelGruppoOrganizzato.style.display = 'none';
    groupSelect.addEventListener('change', function() {
        if (groupSelect.value === 'Gruppo organizzato (specificare)') {
            validationGruppoOrganizzato.style.display = 'block';
            labelGruppoOrganizzato.style.display = 'block';
        } else {
            validationGruppoOrganizzato.style.display = 'none';
            labelGruppoOrganizzato.style.display = 'none';
        }
    });

    //popolo validationTemplate
    let templateSelect = document.getElementById('validationTemplate');
    let templates = JSON.parse(localStorage.getItem('templates')) || [];

    if (templates.length > 0) {
        templates[0].EmailTemplates.sort((a, b) => (a.Nome > b.Nome) ? 1 : -1);
        for (let i = 0; i < templates[0].EmailTemplates.length; i++) {
            let option = document.createElement('option');
            option.value = templates[0].EmailTemplates[i].Nome;
            option.text = templates[0].EmailTemplates[i].Nome;
            templateSelect.appendChild(option);
        }
    } else {
        let option = document.createElement('option');
        option.value = 'Nessun template';
        option.text = 'Nessun template';
        templateSelect.appendChild(option);
    }

    var cover;
    //listener templateSelect per inserire in id=editor contenuto html in template.Body
    templateSelect.addEventListener('change', function() {
        let template = templates[0].EmailTemplates.find(template => template.Nome === templateSelect.value);
        if (template && template.Cover)
        {
            cover = template.Cover;
            sessionStorage.setItem('cover', cover);
            console.log(cover);
            quill.clipboard.dangerouslyPasteHTML(template.Body);
        }
        //let oggetto = document.querySelector('#validationOggetto');
        //oggetto.value = templateSelect.value;
    });
    /*
    var buttonDeleteRequest = document.getElementById('deleteRequest');
    buttonDeleteRequest.addEventListener('click', function() {
        let nRichieste = parseInt(localStorage.getItem('questionBlocks'))+2;
        let selectedIds = JSON.parse(localStorage.getItem('selectedIds')) || {};
        let entries = Object.entries(selectedIds);
        if (entries.length === nRichieste ) {entries.pop();}
        selectedIds = Object.fromEntries(entries);
        console.log(selectedIds);
        localStorage.setItem('selectedIds', JSON.stringify(selectedIds));
    });
    console.log("fatto listerner delete request");
     */

    // Script invio Ticket a Salesforce
// StatoTicket:
// 0: Ticket anonimo, si chiude automaticamente;
// 1: Ticket non  anonimo e chiuso;
// 2: Ticket non anonimo ma deve essere rielaborato;

    /*
    const form = document.querySelector('form');
    let statoTicket = 0;
    let invioEmail = false;
    let token = '';
    let bozza = false;
    form.addEventListener('submit', async (event) => {
        const navigate = useNavigate();
        const submitButton = event.submitter;
        if (submitButton.id === 'sendEmail' || submitButton.id === 'closeNameTicket' || submitButton.id === 'createAnonTicket' || submitButton.id === 'saveTicket' || submitButton.id === 'createBozzaAnonTicket') {
            event.preventDefault();
            //await getToken();
            switch (submitButton.id) {
                case 'createBozzaAnonTicket':
                    if (checkCampiCompilati("createBozzaAnonTicket", quill)) {
                        console.log("sono in createBozzaAnonTicket\n");
                        bozza = true;
                        await post(location, statoTicket, bozza, invioEmail, cover, quill);
                        bozza = false;
                        navigate('/dashboard');
                    }
                    break;
                case 'sendEmail':
                    if (checkCampiCompilati("sendEmail", quill)) {
                        invioEmail = true;
                        statoTicket = checkRichieste();
                        console.log("sono in sendEmail\n");
                        console.log("statoTicket: " + statoTicket);
                        await post(location, statoTicket, bozza, invioEmail, cover, quill);
                        invioEmail = false;
                        statoTicket = 0;
                        navigate('/dashboard');
                    }
                    break;
                case 'closeNameTicket':
                    if (checkCampiCompilati("closeNameTicket", quill)) {
                        statoTicket = checkRichieste();
                        console.log("sono in closeNameTicket\n");
                        console.log("statoTicket: " + statoTicket);
                        await post(location, statoTicket, bozza, invioEmail, cover, quill);
                        statoTicket = 0;
                        navigate('/dashboard');
                    }
                    break;
                case 'createAnonTicket':
                    if (checkCampiCompilati("createAnonTicket", quill)) {
                        console.log("sono in createAnonTicket\n");
                        console.log("statoTicket: " + statoTicket);
                        await post(location, statoTicket, bozza, invioEmail, cover, quill);
                        navigate('/dashboard');
                    }
                    break;
                case 'saveTicket':
                    if (checkCampiCompilati("saveTicket", quill)) {
                        bozza = true;
                        statoTicket = checkRichieste();
                        if (statoTicket ===1) { statoTicket = 2; }
                        await post(location, statoTicket, bozza, invioEmail, cover, quill);
                        bozza = false;
                        navigate('/dashboard');
                    }
                    break;
                default:
                    break;
            }

        }
    });
     */

}

export async function post(location, statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket ) {

    let success = false;
    if (location === 'crea-ticket') {
        const jsonToSend = await getJsonToSendPost(statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket);
        console.log(JSON.stringify(jsonToSend));
        success = await postTicket(jsonToSend);
    } else {
        const jsonToSend = await getJsonToSendPut(statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket);
        console.log(JSON.stringify(jsonToSend));
        success = await putTicket(jsonToSend);
    }
    console.log("success: " + success);
    if (success) {
        sessionStorage.removeItem('selectedContactId');
    }
    return success;
}

export function checkRichieste() {
    let questionBlocks = parseInt(sessionStorage.getItem("questionBlocks"));
    let allChecked = true;

    //primo elemento statico
    let chiusaElement = document.querySelector('#closeRequest0');
    let chiusa = chiusaElement ? chiusaElement.checked : false;
    console.log("Primo elemento chiusa: " + chiusa);
    if (!chiusa) { allChecked = false; }

    for (let i = 1; i < questionBlocks; i++) {
        chiusaElement = document.querySelector('div[data-question="' + i + '"].singleQuestion input[id="closeRequest' + i + '"]');
        chiusa = chiusaElement ? chiusaElement.checked : false;
        console.log("Elemento " + i + " chiusa: " + chiusa);
        if (!chiusa) { allChecked = false; }
    }
    let statoTicket;
    if (allChecked) {
        statoTicket = 1;
    } else {
        statoTicket = 2;
    }
    return statoTicket;
}

export function checkCampiCompilati(buttonId, quill, selectedOption,selectedSede, selectedFirme) {
    let campiCompilati = true;
    let questionBlocks = parseInt(sessionStorage.getItem("questionBlocks"));
    let selectedIds = JSON.parse(sessionStorage.getItem("selectedIds"));
    switch (buttonId) {
        case 'createAnonTicket':
            //document.querySelector('#validationSesso').required = true;
            document.querySelector('#validationLingua').required = true;
            document.querySelector('#validationNazione').required = true;
            document.querySelector('#validationCategoria').required = true;
            document.querySelector('#validationData').required = true;
            document.querySelector('#validationPersone').required = true;
            if (!selectedSede || document.querySelector('#validationCanale').value === '' || document.querySelector('#validationLingua').value === '' || document.querySelector('#validationNazione').value === '' || (!selectedOption || selectedOption.length === 0) || document.querySelector('#validationData').value === '' || document.querySelector('#validationPersone').value === '') {
                campiCompilati = false;
                alert('Compila tutti i campi obbligatori');
            }
            break;
        case 'closeNameTicket':
            //document.querySelector('#validationSesso').required = true;
            document.querySelector('#validationLingua').required = true;
            document.querySelector('#validationNazione').required = true;
            document.querySelector('#validationCategoria').required = true;
            document.querySelector('#validationData').required = true;
            document.querySelector('#validationPersone').required = true;
            document.querySelector('#validationEmail').required = true;
            document.querySelector('#validationTdR0').required = true;
            //document.querySelector('#validationRS0').required = true;
            //document.querySelector('#validationNome').required = true;
            //document.querySelector('#validationCognome').required = true;
            //document.querySelector('#validationComune').required = true;
            if (!selectedSede || document.querySelector('#validationCanale').value === '' || document.querySelector('#validationLingua').value === '' || document.querySelector('#validationNazione').value === '' || (!selectedOption || selectedOption.length === 0) || document.querySelector('#validationData').value === '' || document.querySelector('#validationPersone').value === '' || document.querySelector('#validationEmail').value === '' || document.querySelector('#validationTdR0').value === '') {
                campiCompilati = false;
                alert('Compila tutti i campi obbligatori');
            }
            questionBlocks = parseInt(sessionStorage.getItem("questionBlocks"));
            selectedIds = JSON.parse(sessionStorage.getItem("selectedIds"));

            //richiesta specifica non piu obbligatoria
            if (selectedIds && Object.keys(selectedIds).length === questionBlocks) {
                console.log("selectedIds esiste ed ha lo stesso numero di elementi di questionBlocks");
            } else {
                //campiCompilati = false;
                //alert('Le Rihieste specifiche non sono state selezionate correttamente');
            }

            break;
        case 'sendEmail':
            //document.querySelector('#validationSesso').required = true;
            document.querySelector('#validationLingua').required = true;
            document.querySelector('#validationNazione').required = true;
            document.querySelector('#validationCategoria').required = true;
            document.querySelector('#validationData').required = true;
            document.querySelector('#validationPersone').required = true;
            document.querySelector('#validationEmail').required = true;
            document.querySelector('#validationTdR0').required = true;
            //document.querySelector('#validationRS0').required = true;
            document.querySelector('#validationMittente').required = true;
            document.querySelector('#validationOggetto').required = true;
            document.querySelector('#validationTemplate').required = true;
            document.querySelector('#validationFirme').required = true;
            //document.querySelector('#validationNome').required = true;
            //document.querySelector('#validationCognome').required = true;
            //document.querySelector('#validationComune').required = true;
            if (!selectedSede || document.querySelector('#validationCanale').value === '' || document.querySelector('#validationLingua').value === '' || document.querySelector('#validationNazione').value === '' || (!selectedOption || selectedOption.length === 0) || document.querySelector('#validationData').value === '' || document.querySelector('#validationPersone').value === '' || document.querySelector('#validationEmail').value === '' || document.querySelector('#validationTdR0').value === '' || document.querySelector('#validationMittente').value === '' || document.querySelector('#validationOggetto').value === '' || document.querySelector('#validationTemplate').value === '' || quill.getText() === '' || (!selectedFirme || selectedFirme.length === 0)) {
                campiCompilati = false;
                alert('Compila tutti i campi obbligatori');

            }
            questionBlocks = parseInt(sessionStorage.getItem("questionBlocks"));
            selectedIds = JSON.parse(sessionStorage.getItem("selectedIds"));

            //richiesta specifica non piu obbligatoria
            if (selectedIds && Object.keys(selectedIds).length === questionBlocks) {
                console.log("selectedIds esiste ed ha lo stesso numero di elementi di questionBlocks");
            } else {
                //campiCompilati = false;
                //alert('Le Rihieste specifiche non sono state selezionate correttamente');
            }
            break;
        case 'saveTicket':
            //document.querySelector('#validationComune').required = true;
            //document.querySelector('#validationNome').required = true;
            //document.querySelector('#validationCognome').required = true;
            document.querySelector('#validationTdR0').required = true;
            //document.querySelector('#validationRS0').required = true;
            document.querySelector('#validationEmail').required = true;
            console.log("sono in saveTicket");
            if (!selectedSede || document.querySelector('#validationCanale').value === '' || document.querySelector('#validationEmail').value === '' || document.querySelector('#validationTdR0').value === '') {
                campiCompilati = false;
                alert('Compila almeno la sede, il canale, email, cognome e almeno una richiesta');
            }
            questionBlocks = parseInt(sessionStorage.getItem("questionBlocks"));
            selectedIds = JSON.parse(sessionStorage.getItem("selectedIds"));

            //richiesta specifica non piu obbligatoria
            if (selectedIds && Object.keys(selectedIds).length === questionBlocks) {
                console.log("selectedIds esiste ed ha lo stesso numero di elementi di questionBlocks");
            } else {
                //campiCompilati = false;
                //alert('Le Rihieste specifiche non sono state selezionate correttamente');
            }
            break;
        case 'createBozzaAnonTicket':
            if (!selectedSede || document.querySelector('#validationCanale').value === '' )
            {
                campiCompilati = false;
                alert('Compila almeno la sede e il canale');
            }

    }
    return campiCompilati;
}

async function getJsonToSendPost(statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket) {
    const webStorageCache = new WebStorageCache();
    let operatoreId = webStorageCache.get('salesforceId');
    let customerId = "";
    let customer = {};
    var nome = document.querySelector('#validationNome').value || '';
    var cognome = document.querySelector('#validationCognome').value || '';
    var email = document.querySelector('#validationEmail').value || '';
    var comune = document.querySelector('#validationComune').value || '';
    return getContact(cognome, email, comune, nome)
        .then(data => {
            customer = {
                name: document.querySelector('#validationNome').value || '',
                lastname: document.querySelector('#validationCognome').value || '',
                email: document.querySelector('#validationEmail').value || '',
                titolo:document.querySelector('#validationTitolo').value || '',
                ruolo : document.querySelector('#validationRuolo').value || '',
                professione: document.querySelector('#validationProfessione').value || '',
            };

            if (sessionStorage.getItem('selectedContactId')) {
                customer.salesforceId = sessionStorage.getItem('selectedContactId');
                customerId = sessionStorage.getItem('selectedContactId');
            } else if (data && data[0] && data[0].Id) {
                customer.salesforceId = data[0].Id;
                customerId = data[0].Id;
            }

            //ciclo per prendere tutte le richieste
            let richieste = [];
            let risorsaId = 'sagrapeperone1234';

            let questionBlocks = parseInt(sessionStorage.getItem("questionBlocks"));

            //la prima richiesta non ha il numero
            //validationRS0 in quanto c'è una ripetizione di id
            /*
            let tipoRichiestaElement = document.querySelector('#validationTdR0');
            let tipoRichiesta = '';
            console.log(tipoRichiestaElement.value);
            switch (tipoRichiestaElement.value) {
                case 'resources':
                    tipoRichiesta = 'Risorsa';
                    break;
                case 'events':
                    tipoRichiesta = 'Evento';
                    break;
                case 'products':
                    tipoRichiesta = 'Prodotto';
                    break;
                case 'tables':
                    tipoRichiesta = 'Tabella';
                    break;
                case 'altro':
                    tipoRichiesta = 'Altro';
                    break;
                default:
                    tipoRichiesta = '';
                    break;
            }

            let richiestaSpecificaElement = document.querySelector('#validationRS0');
            let richiestaSpecifica = richiestaSpecificaElement ? richiestaSpecificaElement.value : '';
            let richiestaAltroElement = document.querySelector('#floatingTextarea0');
            let richiestaAltro = richiestaAltroElement ? richiestaAltroElement.value : '';
            let chiusaElement = document.querySelector('#closeRequest0');
            let chiusa = chiusaElement ? chiusaElement.checked : false;
            richieste.push({
                tipoRichiesta: tipoRichiesta,
                richiestaSpecifica: richiestaSpecifica,
                richiestaAltro: richiestaAltro,
                chiusa: chiusa,
                risorsaId: risorsaId
            });
             */
            let tipoRichiesta = '';
            for (let i = 0; i < questionBlocks; i++) {
                let tipoRichiestaElement = document.querySelector(`div[data-question="${i}"].singleQuestion select[id="validationTdR${i}"]`);
                switch (tipoRichiestaElement.value) {
                    case 'resources':
                        tipoRichiesta = 'Risorsa';
                        break;
                    case 'events':
                        tipoRichiesta = 'Evento';
                        break;
                    case 'products':
                        tipoRichiesta = 'Prodotto';
                        break;
                    case 'tables':
                        tipoRichiesta = 'Tabella';
                        break;
                    case 'altro':
                        tipoRichiesta = 'Altro';
                        break;
                    default:
                        tipoRichiesta = '';
                        break;
                }
                let richiestaSpecificaElement = document.querySelector('#validationRS' + i);
                let richiestaSpecifica = richiestaSpecificaElement && richiestaSpecificaElement.value.trim() !== '' ? richiestaSpecificaElement.value.trim() : '-';
                let richiestaAltroElement = document.querySelector('div[data-question="' + i + '"].singleQuestion textarea[id="floatingTextarea' + i + '"]');
                let richiestaAltro = richiestaAltroElement ? richiestaAltroElement.value : '';
                let chiusaElement = document.querySelector('div[data-question="' + i + '"].singleQuestion input[id="closeRequest' + i + '"]');
                let chiusa = chiusaElement ? chiusaElement.checked : false;
                let selectedIds = JSON.parse(sessionStorage.getItem('selectedIds')) || {};
                risorsaId = selectedIds[i] || '-';

                richieste.push({
                    tipoRichiesta: tipoRichiesta,
                    richiestaSpecifica: richiestaSpecifica,
                    richiestaAltro: richiestaAltro,
                    chiusa: chiusa,
                    risorsaId: risorsaId
                });
            }
            if (document.querySelector('#validationPersone').value === '') {
                document.querySelector('#validationPersone').value = 1;
            }

            let urlOggetti = 'google.com';// boh
            let validationSede = document.querySelector('#validationSede');
            if (statoTicket!==0) {
                //stato ticket to string
                return {

                    "data": [{
                        "StatoTicket": statoTicket.toString(),
                        "origin": "Email",
                        "bozza": bozza,
                        "sedeId": selectedSede,
                        "operatoreId": operatoreId,
                        "ragioneSociale": document.querySelector('#validationRS').value,
                        "tipologia": document.querySelector('#typeDropdownButton').textContent,
                        "comuneAzienda": document.querySelector('#validationComuneRS').value,
                        "nazioneAzienda": document.querySelector('#validationNazioneRS').value,
                        "customerSalesforceId": customer.salesforceId,
                        "customer": customer,
                        "canale": document.querySelector('#validationCanale').value,
                        "lingua": document.querySelector('#validationLingua').value,
                        "sesso": document.querySelector('#validationSesso').value,
                        "provenienzaNazione": document.querySelector('#validationNazione').value,
                        "provenienzaRegione": document.querySelector('#validationRegione').value,
                        "provenienzaComune": document.querySelector('#validationComune').value,
                        "categoriaInfo": selectedOption ? selectedOption.map(option => option.value).join(',') : '',
                        "data": document.querySelector('#validationData').value,
                        "ripetiXn": document.querySelector('#validationPersone').value,
                        "mezzoDiTrasporto": document.querySelector('#validationMdT').value,
                        "compagniDiViaggio": document.querySelector('#validationCdV').value,
                        "gruppoOrganizzatoSpecifica": document.querySelector('#validationGruppoOrganizzato').value,
                        "richieste": richieste,
                        "invioEmail": invioEmail,
                        "codaAssegnazione": codaAssegnazione,
                        "notificaTicket": notificaTicket,
                        "oggettoEmail": document.querySelector('#validationOggetto').value,
                        "testoEmail": quill.root.innerHTML,
                        "cover": cover,
                        "emailCC": document.querySelector('#validationCC').value,
                        "sender": document.querySelector('#validationMittente').value,
                        "Firma": selectedFirme || '',
                    }]
                };
            }else{
                return {
                    "data": [{
                        "StatoTicket": statoTicket,
                        "origin": "Email",
                        "bozza": bozza,
                        "sedeId": selectedSede,
                        "operatoreId": operatoreId,
                        "canale": document.querySelector('#validationCanale').value,
                        "sesso": document.querySelector('#validationSesso').value,
                        "lingua": document.querySelector('#validationLingua').value,
                        "provenienzaNazione": document.querySelector('#validationNazione').value,
                        "provenienzaRegione": document.querySelector('#validationRegione').value,
                        "provenienzaComune": document.querySelector('#validationComune').value,
                        "categoriaInfo": selectedOption ? selectedOption.map(option => option.value).join(',') : '',
                        "data": document.querySelector('#validationData').value,
                        "ripetiXn": document.querySelector('#validationPersone').value
                    }]
                };
            }
        })
        .catch(error => {
            console.error("There has been a problem with your fetch operation: ", error);
        });

}

async function getJsonToSendPut(statoTicket, bozza, invioEmail, cover, quill, selectedOption,selectedSede, selectedFirme, codaAssegnazione, notificaTicket) {
    let sedeId = "001MB000006Q69ZYAS";//fittizio
    let validationSede = document.querySelector('#validationSede');
    const webStorageCache = new WebStorageCache();
    let operatoreId = webStorageCache.get('salesforceId');
    let ticket = getTicket();
    let oldNome = '';
    let oldCognome = '';
    let oldComune = '';
    let oldEmail = '';
    let customerId = '';
    if (ticket && ticket.Contact && ticket.Contact.FirstName)
        oldNome = ticket.Contact.FirstName || '';
    if (ticket && ticket.Contact && ticket.Contact.LastName)
        oldCognome = ticket.Contact.LastName || '';
    if (ticket && ticket.Provenienza_Comune__c)
        oldComune = ticket.Provenienza_Comune__c || '';
    if (ticket && ticket.Contact && ticket.Contact.Email)
        oldEmail = ticket.Contact.Email || '';
    if (ticket && ticket.Contact && ticket.Contact.Id)
        customerId = ticket.Contact.Id || '';
    let customer = {};
    if (statoTicket === 0) {
        return {
            "data": [{
                "StatoTicket": statoTicket,
                "origin": "Email",
                "bozza": bozza,
                "sedeId": selectedSede,
                "operatoreId": operatoreId,
                "canale": document.querySelector('#validationCanale').value,
                "sesso": document.querySelector('#validationSesso').value,
                "lingua": document.querySelector('#validationLingua').value,
                "provenienzaNazione": document.querySelector('#validationNazione').value,
                "provenienzaRegione": document.querySelector('#validationRegione').value,
                "provenienzaComune": document.querySelector('#validationComune').value,
                "categoriaInfo": Array.isArray(selectedOption)
                    ? selectedOption.map(option => option.value).join(',')
                    : selectedOption ? selectedOption.value : '',
                "data": document.querySelector('#validationData').value,
                "ripetiXn": document.querySelector('#validationPersone').value
            }]
        };
    } else {

        return getContact(oldCognome, oldEmail, oldComune, oldNome)
            .then(data => {
                customer = {
                    name: document.querySelector('#validationNome').value || '',
                    lastname: document.querySelector('#validationCognome').value || '',
                    email: document.querySelector('#validationEmail').value || '',
                    titolo:document.querySelector('#validationTitolo').value || '',
                    ruolo : document.querySelector('#validationRuolo').value || '',
                    professione: document.querySelector('#validationProfessione').value || '',
                };

                if (sessionStorage.getItem('selectedContactId')) {
                    customer.salesforceId = sessionStorage.getItem('selectedContactId');
                    customerId = sessionStorage.getItem('selectedContactId');
                } else if (data && data[0] && data[0].Id) {
                    customer.salesforceId = data[0].Id;
                    customerId = data[0].Id;
                } else {
                    if (customerId !== '') {
                        customer.salesforceId = customerId;
                    }
                }

                //ciclo per prendere tutte le richieste
                let richieste = [];
                let risorsaId = '';

                let questionBlocks = parseInt(sessionStorage.getItem("questionBlocks"));

                //la prima richiesta non ha il numero
                //validationRS0 in quanto c'è una ripetizione di id
                let tipoRichiestaElement = document.querySelector('#validationTdR0');
                let tipoRichiesta = '';
                switch (tipoRichiestaElement.value) {
                    case 'resources':
                        tipoRichiesta = 'Risorsa';
                        break;
                    case 'events':
                        tipoRichiesta = 'Evento';
                        break;
                    case 'products':
                        tipoRichiesta = 'Prodotto';
                        break;
                    case 'tables':
                        tipoRichiesta = 'Tabella';
                        break;
                    case 'altro':
                        tipoRichiesta = 'Altro';
                        break;
                    default:
                        tipoRichiesta = '';
                }

                let richiestaSpecificaElement = document.querySelector('#validationRS0');
                let richiestaSpecifica = richiestaSpecificaElement && richiestaSpecificaElement.value.trim() !== '' ? richiestaSpecificaElement.value.trim() : '-';
                let richiestaAltroElement = document.querySelector('#floatingTextarea0');
                let richiestaAltro = richiestaAltroElement ? richiestaAltroElement.value : '';
                let chiusaElement = document.querySelector('#closeRequest0');
                let chiusa = chiusaElement ? chiusaElement.checked : false;
                let selectedIds = JSON.parse(sessionStorage.getItem('selectedIds')) || {};
                risorsaId = selectedIds[0] || '-';
                let salesforceIdRichiesta = '';
                if(ticket.Richieste_Tickets__r && ticket.Richieste_Tickets__r.records[0] && ticket.Richieste_Tickets__r.records[0].Id){
                    salesforceIdRichiesta = ticket.Richieste_Tickets__r.records[0].Id;
                    richieste.push({
                        salesforceId: salesforceIdRichiesta,
                        tipoRichiesta: tipoRichiesta,
                        richiestaSpecifica: richiestaSpecifica,
                        richiestaAltro: richiestaAltro,
                        chiusa: chiusa,
                        risorsaId: risorsaId
                    });
                } else {
                    richieste.push({
                        tipoRichiesta: tipoRichiesta,
                        richiestaSpecifica: richiestaSpecifica,
                        richiestaAltro: richiestaAltro,
                        chiusa: chiusa,
                        risorsaId: risorsaId
                    });
                }

                for (let i = 1; i < questionBlocks; i++) {
                    let tipoRichiestaElement = document.querySelector(`div[data-question="${i}"].singleQuestion select[id="validationTdR${i}"]`);
                    switch (tipoRichiestaElement.value) {
                        case 'resources':
                            tipoRichiesta = 'Risorsa';
                            break;
                        case 'events':
                            tipoRichiesta = 'Evento';
                            break;
                        case 'products':
                            tipoRichiesta = 'Prodotto';
                            break;
                        case 'tables':
                            tipoRichiesta = 'Tabella';
                            break;
                        case 'altro':
                            tipoRichiesta = 'Altro';
                            break;
                        default:
                            tipoRichiesta = '';
                    }
                    richiestaSpecificaElement = document.querySelector('#validationRS' + i);
                    richiestaSpecifica = richiestaSpecificaElement && richiestaSpecificaElement.value.trim() !== '' ? richiestaSpecificaElement.value.trim() : '-';
                    richiestaAltroElement = document.querySelector('div[data-question="' + i + '"].singleQuestion textarea[id="floatingTextarea' + i + '"]');
                    richiestaAltro = richiestaAltroElement ? richiestaAltroElement.value : '';
                    chiusaElement = document.querySelector('div[data-question="' + i + '"].singleQuestion input[id="closeRequest' + i + '"]');
                    chiusa = chiusaElement ? chiusaElement.checked : false;
                    risorsaId = selectedIds[i] || '-';
                    salesforceIdRichiesta = '';
                    if(ticket.Richieste_Tickets__r.records[i] && ticket.Richieste_Tickets__r.records[i].Id){
                        salesforceIdRichiesta = ticket.Richieste_Tickets__r.records[i].Id;
                        richieste.push({
                            salesforceId: salesforceIdRichiesta,
                            tipoRichiesta: tipoRichiesta,
                            richiestaSpecifica: richiestaSpecifica,
                            richiestaAltro: richiestaAltro,
                            chiusa: chiusa,
                            risorsaId: risorsaId
                        });
                    } else {
                        richieste.push({
                            tipoRichiesta: tipoRichiesta,
                            richiestaSpecifica: richiestaSpecifica,
                            richiestaAltro: richiestaAltro,
                            chiusa: chiusa,
                            risorsaId: risorsaId
                        });
                    }
                }
                if (document.querySelector('#validationPersone').value === '') {
                    document.querySelector('#validationPersone').value = 1;
                }

                let urlOggetti = 'google.com';// boh
                //TO DO SPECIFICA COMPAGNI DI VIAGGIO
                console.log("statoTicket prima del post: " + statoTicket);
                console.log("cover: " + cover);
                return {

                    "data": [{
                        "StatoTicket": statoTicket,
                        "origin": "Email",
                        "bozza": bozza,
                        "sedeId": selectedSede,
                        "operatoreId": operatoreId,
                        "ragioneSociale": document.querySelector('#validationRS').value,
                        "tipologia": document.querySelector('#typeDropdownButton').textContent,
                        "comuneAzienda": document.querySelector('#validationComuneRS').value,
                        "nazioneAzienda": document.querySelector('#validationNazioneRS').value,
                        "customerSalesforceId": customer.salesforceId,
                        "customer": customer,
                        "canale": document.querySelector('#validationCanale').value,
                        "lingua": document.querySelector('#validationLingua').value,
                        "sesso": document.querySelector('#validationSesso').value,
                        "provenienzaNazione": document.querySelector('#validationNazione').value,
                        "provenienzaRegione": document.querySelector('#validationRegione').value,
                        "provenienzaComune": document.querySelector('#validationComune').value,
                        "categoriaInfo": Array.isArray(selectedOption)
                            ? selectedOption.map(option => option.value).join(',')
                            : selectedOption ? selectedOption.value : '',
                        "data": document.querySelector('#validationData').value,
                        "ripetiXn": document.querySelector('#validationPersone').value,
                        "mezzoDiTrasporto": document.querySelector('#validationMdT').value,
                        "compagniDiViaggio": document.querySelector('#validationCdV').value,
                        "gruppoOrganizzatoSpecifica": document.querySelector('#validationGruppoOrganizzato').value,
                        "richieste": richieste,
                        "invioEmail": invioEmail,
                        "codaAssegnazione": codaAssegnazione,
                        "notificaTicket": notificaTicket,
                        "oggettoEmail": document.querySelector('#validationOggetto').value,
                        "testoEmail": quill.root.innerHTML,
                        "cover": cover,
                        "emailCC": document.querySelector('#validationCC').value,
                        "sender": document.querySelector('#validationMittente').value,
                        "Firma": selectedFirme || '',
                    }]
                };
            })
            .catch(error => {
                console.error("There has been a problem with your fetch operation: ", error);
            });
    }
}

function getTicket() {
    var tickets = JSON.parse(localStorage.getItem('tickets')) || [];
    var ticket;
    var ticketsBozze;
    var ticketId = sessionStorage.getItem('editTicketId');

    if (tickets !== null) {
        ticket = tickets.filter(ticket => ticket.Id === ticketId)[0];
        if (ticket === undefined) {
            ticketsBozze = JSON.parse(localStorage.getItem('ticketsBozze')) || [];
            ticket = ticketsBozze.filter(ticket => ticket.Id === ticketId)[0];
        }
    } else {
        ticketsBozze = JSON.parse(localStorage.getItem('ticketsBozze')) || [];
        ticket = ticketsBozze.filter(ticket => ticket.Id === ticketId)[0];
    }
    return ticket;
}